<template>
  <div class="wt-tabs">
    <div class="wt-tabs-content">
      <div class="wt-tabs-header">
        <div class="wt-tabs-wrapper">
          <div class="wt-tabs-scroll">
            <div class="wt-tabs-nav">
              <div class="wt-tab" :class="{'is-active' : currentRoute.name == 'WELCOME'}" @click="onClickHomeTab">
                <i class="wt-tab-icon el-icon-s-platform"></i>
                <span>首页</span>
                <i class="wt-tab-icon-close el-icon-close"></i>
              </div>
              <div class="wt-tab is-closeable" v-for="(viewItem, viewIndex) in visitedViews" :key="viewIndex"
                   :class="{'is-active' : currentRoute.name == viewItem.name}" @click="onClickTab(viewItem)">
                <i v-show="theme.isTabIconVisible" class="wt-tab-icon" :class="[viewItem.meta.icon]"></i>
                <span>{{viewItem.meta && viewItem.meta.title}}</span>
                <i class="wt-tab-icon-close el-icon-close" @click.stop="onClickTabClose(viewItem)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'WtTabs',
  created() {
  },
  methods: {
    onClickHomeTab() {
      this.$router.push({ path: '/welcome' })
    },
    onClickTab(item) {
      this.$router.push({ name: item.name })
    },
    onClickTabClose(item) {
      const that = this
      this.$store.dispatch('delView', item).then(({ visitedViews }) => {
        if (visitedViews && visitedViews.length > 0) {
          that.$router.push({ name: visitedViews[visitedViews.length - 1].name })
        } else {
          that.onClickHomeTab()
        }
      })
    }
  },
  computed: {
    ...mapState({
      theme: state => state.app.theme,
      currentRoute: state => state.app.currentRoute,
      menuNameList: state => state.app.menuNameList,
      visitedViews: state => state.wtTabs.visitedViews
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/variables.scss";
  .wt-tabs {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    min-height: $--wt-tabs-height;
    padding-right: 20px;
    padding-left: 20px;
    user-select: none;
    border-top: 1px solid #f6f6f6;
    .wt-tabs-content{
      height: 38px;
      width: calc(100% - 40px);
    }
    .wt-tabs-header {
      border-bottom: 0;
      padding: 0;
      position: relative;
      margin: 0 0 15px;
    }
    .wt-tabs-wrapper {
      overflow: hidden;
      margin-bottom: -1px;
      position: relative;
    }
    .wt-tabs-scroll{
      overflow: hidden;
    }
    .wt-tabs-nav{
      transform: translateX(0px);
      border: 0;
      white-space: nowrap;
      position: relative;
      float: left;
    }
    .wt-tab{
      cursor: pointer;
      height: 38px;
      margin-top: 5.95px;
      margin-right: -18px;
      line-height: 38px;
      text-align: center;
      border: 0;
      display: inline-block;
      list-style: none;
      font-size: 14px;
      position: relative;
      padding: 0 30px;
      transition: padding .3s cubic-bezier(.645,.045,.355,1)!important;
      .wt-tab-icon{
        margin-right: 4px;
      }
      .wt-tab-icon-close {
        position: relative;
        font-size: 12px;
        width: 0;
        height: 14px;
        vertical-align: middle;
        line-height: 15px;
        overflow: hidden;
        top: -1px;
        right: -2px;
        transform-origin: 100% 50%;
        margin-left: 5px;
        transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1);
      }
      .wt-tab-icon-close{
        border-radius: 50%;
        &:hover{
          background-color: #c0c4cc;
        }
      }
      &:hover{
        z-index: 2;
        color: #5C6173;
        background: #F2F5FC;
        mask: url('./assets/tab_bg.png');
        mask-size: 100% 100%;
      }
      &.is-active {
        color: $--wt-color-primary;
        background: $--wt-color-primary-light;
        mask: url('./assets/tab_bg.png');
        mask-size: 100% 100%;
      }
    }
    .wt-tab.is-closeable {
      &:hover{
        .wt-tab-icon-close {
          width: 14px;
        }
      }
      &.is-active {
        .wt-tab-icon-close {
          width: 14px;
        }
      }
    }
  }
</style>
