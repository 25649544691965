<template>
  <div class="tree-container" :style="{height: treeHeight}">
    <div class="tree-filter">
      <el-input placeholder="请输入关键词" size="mini"  v-model="filterText"></el-input>
    </div>
    <el-tree class="wt-data-tree tree" :default-expanded-keys="[1]"
             ref="dataTree"
             :show-checkbox="defaultProps.showCheckbox"
             node-key="id"
             :filter-node-method="onFilterNode"
             :default-checked-keys="checkData"
             :data="treeData" :props="{
               ...defaultProps,
               disabled: disabledFun
             }" :expand-on-click-node="false"
             @current-change="handleCurrentChanged" @check-change="handleCheckChange"></el-tree>
  </div>
</template>

<script>
export default {
  name: 'wtDataTree',
  isAutoComponent: true,
  model: {
    prop: 'checkData',
    event: 'treeChange'
  },
  props: {
    treeHeight: {
      type: String,
      default: '300px'
    },
    treeData: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          label: 'label',
          showCheckbox: true,
          type: ''
        }
      }
    },
    checkData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filterText: ''
    }
  },
  methods: {
    // 节点过滤方法
    onFilterNode(value, data) {
      if (!value) return true
      return data[this.defaultProps.label].indexOf(value) !== -1
    },
    // 当前节点点击事件
    handleCurrentChanged(val) {
      this.$emit('on-node-click', val)
    },
    // 监听节点选择变化，获取当前选择节点
    handleCheckChange(e) {
      const nodeKeys = this.$refs.dataTree.getCheckedKeys(true)
      // const nodes = this.$refs.dataTree.getCheckedNodes()
      // console.log('nodeKeys', nodeKeys)
      this.$emit('treeChange', nodeKeys)
    },
    disabledFun(data, node) {
      // 只在选择人的时候条件生效
      if (data.isExistUser === 0 && this.defaultProps.type === 'user') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.dataTree.filter(val)
    },
    checkData(newData, oldData) {
      this.$refs.dataTree.setCheckedNodes(newData)
    }
  }
}
</script>

<style scoped lang="scss">
  .tree-container {overflow-y: auto;box-sizing: border-box;padding: 15px 0 15px 15px;}
  .tree-filter{padding: 20px 16px;}
  .wt-data-tree{padding: 0px 16px;}
</style>
