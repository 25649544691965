<template>
  <div class="wt-container">
    <h1>welcome</h1>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'dashboard',
  components: {},
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      isFromLoading: false
    }
  },
  mounted() {
  },
  created() {

  },
  methods: {
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {},
      moduleList: state => state.auth.moduleList
    })
  }
}
</script>
<style lang="scss" scoped>
.wt-fullpage-container {
  background: none;
}
</style>
