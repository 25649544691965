<template>
  <fragment>
    <li v-if="data.meta && !data.meta.hidden && data.children && data.children.length > 0" class="wt-sub-menu" :class="{'is-opened': isOpened}">
      <div class="wt-sub-menu-title" @click="onClickOpen(data)" :style="{'padding-left': 20 + (level - 1) * 12 + 'px'}">
        <i v-show="theme.isMenuIconVisible" :class="[data.meta && data.meta.icon]"></i>
        <span>{{data.meta && data.meta.title}}</span>
        <i class="el-icon-arrow-down wt-sub-menu-arrow"></i>
      </div>
      <transition v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:after-enter="afterEnter"
        v-on:before-leave="beforeLeave" v-on:leave="leave" v-on:after-leave="afterLeave" >
        <ul class="wt-menu" v-if="isOpened">
          <wt-menu-item v-for="(subItem, subIndex) in data.children" :key="'subIndex_'+subIndex" :data="subItem" :level="level + 1"></wt-menu-item>
        </ul>
      </transition>
    </li>
    <li v-else-if="data.meta && !data.meta.hidden" class="wt-menu-item" :class="{'is-active': subMenuName == data.name}"
        :style="{'padding-left': 20 + (level - 1) * 12 + 'px'}" @click="onClickSubMenu(data)">
      <i v-show="theme.isMenuIconVisible" :class="[data.meta && data.meta.icon]"></i>
      <span>{{data.meta && data.meta.title}}</span>
    </li>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'
import { addClass, removeClass } from 'element-ui/src/utils/dom'

export default {
  name: 'WtMenuItem',
  props: {
    data: {
      default: () => {}
    },
    level: {
      default: 0
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    onClickOpen(item) {
      this.$store.dispatch('toggleMenu', item)
    },
    onClickSubMenu(item) {
      this.$router.push({ name: item.name })
    },
    beforeEnter(el) {
      addClass(el, 'wt-collapse-transition')
      if (!el.dataset) el.dataset = {}

      el.dataset.oldPaddingTop = el.style.paddingTop
      el.dataset.oldPaddingBottom = el.style.paddingBottom

      el.style.height = '0'
      el.style.paddingTop = 0
      el.style.paddingBottom = 0
    },
    enter(el) {
      el.dataset.oldOverflow = el.style.overflow
      if (el.scrollHeight !== 0) {
        el.style.height = el.scrollHeight + 'px'
        el.style.paddingTop = el.dataset.oldPaddingTop
        el.style.paddingBottom = el.dataset.oldPaddingBottom
      } else {
        el.style.height = ''
        el.style.paddingTop = el.dataset.oldPaddingTop
        el.style.paddingBottom = el.dataset.oldPaddingBottom
      }

      el.style.overflow = 'hidden'
    },
    afterEnter(el) {
      // for safari: remove class then reset height is necessary
      removeClass(el, 'wt-collapse-transition')
      el.style.height = ''
      el.style.overflow = el.dataset.oldOverflow
    },
    beforeLeave(el) {
      if (!el.dataset) el.dataset = {}
      el.dataset.oldPaddingTop = el.style.paddingTop
      el.dataset.oldPaddingBottom = el.style.paddingBottom
      el.dataset.oldOverflow = el.style.overflow

      el.style.height = el.scrollHeight + 'px'
      el.style.overflow = 'hidden'
    },
    leave(el) {
      if (el.scrollHeight !== 0) {
        addClass(el, 'wt-collapse-transition')
        el.style.height = 0
        el.style.paddingTop = 0
        el.style.paddingBottom = 0
      }
    },
    afterLeave(el) {
      removeClass(el, 'wt-collapse-transition')
      el.style.height = ''
      el.style.overflow = el.dataset.oldOverflow
      el.style.paddingTop = el.dataset.oldPaddingTop
      el.style.paddingBottom = el.dataset.oldPaddingBottom
    }
  },
  computed: {
    isOpened() {
      return this.menuNameList.indexOf(this.data.name) !== -1
    },
    ...mapState({
      theme: state => state.app.theme,
      menuNameList: state => state.app.menuNameList,
      mainMenuName: state => state.app.mainMenuName,
      subMenuName: state => state.app.subMenuName
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
