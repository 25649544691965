import Vue from 'vue'
import store from '@/store/index'

Vue.directive('has-any-premission', {
  bind: (el, binding, vnode) => {
    el.style.display = hasAnyPermission(binding ? binding.value : []) ? el.style.display : 'none'
  }
})

Vue.directive('has-any-role', {
  bind: (el, binding, vnode) => {
    el.style.display = hasAnyRole(binding ? binding.value : []) ? el.style.display : 'none'
  }
})

Vue.directive('has-any-module', {
  bind: (el, binding, vnode) => {
    el.style.display = hasAnyModule(binding ? binding.value : []) ? el.style.display : 'none'
  }
})

function hasAnyPermission(values) {
  let isValidate = false
  const codes = store.state.auth.permissionCodeList || []
  const isSkipCheck = !store.state.app.theme.isAuthEnable
  if (isSkipCheck) {
    isValidate = true
  } else if (values && values.length > 0) {
    values.forEach(item => {
      if (codes.indexOf(item) >= 0) {
        isValidate = true
        return true
      }
    })
  }
  return isValidate
}

function hasAnyModule(values) {
  let isValidate = false
  const codes = store.state.auth.moduleCodeList || []
  const isSkipCheck = !store.state.app.theme.isAuthEnable
  if (isSkipCheck) {
    isValidate = true
  } else if (values && values.length > 0) {
    values.forEach(item => {
      if (codes.indexOf(item) >= 0) {
        isValidate = true
        return true
      }
    })
  }
  return isValidate
}

function hasAnyRole(values) {
  let isValidate = false
  const codes = store.state.auth.roleCodeList || []
  const isSkipCheck = !store.state.app.theme.isAuthEnable
  if (isSkipCheck) {
    isValidate = true
  } else if (values && values.length > 0) {
    values.forEach(item => {
      if (codes.indexOf(item) >= 0) {
        isValidate = true
        return true
      }
    })
  }
  return isValidate
}

export default {
  hasAnyPermission: hasAnyPermission,
  hasAnyModule: hasAnyModule,
  hasAnyRole: hasAnyRole
}
