<template>
  <div class="wt-col wt-col-6">
    <div class="wt-row wt-form-item" :class="[className, isAutoHidden ? 'wt-form-item-hidden' : '']">
      <div class="wt-col wt-form-item-label">
        <label>{{title}}</label>
      </div>
      <div class="wt-col wt-form-item-control" >
        <div class="wt-form-item-control-input">
          <div class="wt-form-item-control-input-content">
            <!--  input  -->
            <template v-if="type && type === 'input'">
              <el-input @input="inputValChange" v-model="dataValue" :placeholder="placeholder ? placeholder : '请输入' + title" :size="size" v-bind="$attrs" :value="value"></el-input>
            </template>
            <!--  daterange  -->
            <template v-if="type && type === 'daterange'">
              <el-date-picker type="daterange" v-model="dataValue" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                              :placeholder="placeholder ? placeholder : '请选择' + title" :size="size" v-bind="$attrs"></el-date-picker>
            </template>
            <!--  date  -->
            <template v-if="type && type === 'date'">
              <el-date-picker type="date" v-model="dataValue" :placeholder="placeholder ? placeholder : '请选择' + title" value-format="yyyy-MM-dd" :size="size" v-bind="$attrs"></el-date-picker>
            </template>
            <!--  datetimerange  -->
            <template v-if="type && type === 'datetimerange'">
              <el-date-picker type="datetimerange" v-model="dataValue" start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss"
                              :picker-options="pickerOptions"
                              :placeholder="placeholder ? placeholder : '请选择' + title" :size="size" v-bind="$attrs"></el-date-picker>
            </template>
            <!--  datetime  -->
            <template v-if="type && type === 'datetime'">
              <el-date-picker type="datetime" v-model="dataValue" :placeholder="placeholder ? placeholder : '请选择' + title" value-format="yyyy-MM-dd HH:mm:ss" :size="size" v-bind="$attrs"></el-date-picker>
            </template>
            <!--  select  -->
            <template v-else-if="type && type === 'select'">
              <el-select v-model="dataValue" :placeholder="placeholder ? placeholder : '请选择' + title" :size="size" v-bind="$attrs">
                <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </template>

            <!--  cb-select  -->
            <template v-else-if="type && type === 'cb-select'">
              <el-select @change="selectChangeCb" v-model="dataValue" :placeholder="placeholder ? placeholder : '请选择' + title" :size="size" v-bind="$attrs">
                <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </template>

            <!--  multi-select  -->
            <template v-else-if="type && type === 'multi-select'">
              <el-select v-model="dataValue" multiple :multiple-limit="multipleLimit" :placeholder="placeholder ? placeholder : '请选择' + title" :size="size" v-bind="$attrs">
                <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </template>
            <!--  custom  -->
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WtSearchFormItem',
  isAutoComponent: true,
  props: {
    title: {},
    type: {},
    dataList: {},
    value: {},
    className: {},
    placeholder: {},
    titleStyle: {},
    selectChangeCb: {},
    multipleLimit: {},
    isAutoHidden: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      size: 'mini',
      dataValue: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近半年',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一年',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  created() {
    if ((this.type === 'select' || this.type === 'multi-select') && this.value) {
      this.dataValue = this.value
    }
  },
  watch: {
    value(val, oldValue) {
      if (val !== this.dataValue) {
        this.dataValue = val
      }
    },
    dataValue(val, oldValue) {
      if (val !== this.value) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    // 输入框输入事件
    inputValChange(e) {
      this.$emit('on-input-change', e)
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  /*.wt-search-form-item .title {width: 100px; text-align: right;margin-right: 8px;}*/
  /*.wt-search-form-item .content{flex: 1;max-width: 240px;}*/
  /*.wt-search-form-item .wt-input-datetimerange{flex: 1;max-width: 340px;}*/

  /*.wt-table-compact .wt-search-form-item .title {width: auto; text-align: right;margin-right: 8px;}*/
</style>
