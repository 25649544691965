<script type="text/jsx">
export default {
  name: 'WtEnumContent',
  isAutoComponent: true,
  props: {
    text: String,
    color: String,
    weight: {
      type: String,
      default: 's'
    },
    icon: String,
    iconColor: String,
    image: String
  },
  mounted() {},
  methods: {},
  computed: {},
  render(h) {
    return (<div class='wt-enum-content'>
      {
        this.$wtUtil.isNotBlank(this.icon) && <i class={['wt-icon', this.icon]} style={{ color: this.iconColor }}></i>
      }
      {
        this.$wtUtil.isNotBlank(this.image) && <img class={['wt-image']} style={{ color: this.iconColor }} src={this.image} />
      }
      { this.$slots.default }
      <span style={{ color: this.color, fontWeight: this.weight }}>{ this.text }</span>
    </div>)
  }
}
</script>
<style lang="scss" scoped>
.wt-enum-content{
  display: flex;align-items: center;
  .wt-icon{font-size: 16px;margin-right: 4px;}
  .wt-image{width: 16px; height: 16px;margin-right: 4px;}
}
</style>
