// import IconTest from '@/assets/images/icon_test.png'
const wtConst = {
  ISPUBLISH_ENUM_CONTENT: {
    '0': { text: '禁用', color: '#333333', icon: 'el-icon-warning', iconColor: '#DE504E' },
    '1': { text: '启用', color: '#333333', icon: 'el-icon-success', iconColor: '#50D4AB' }
  },
  ISPUBLISH_ENUM_OPTIONS: [
    { value: 1, label: '已启用' },
    { value: 0, label: '已禁用' }
  ],
  MENU_ISHIDDEN_ENUM_CONTENT: {
    '0': { text: '显示', color: '#50D4AB', weight: 'bold' },
    '1': { text: '隐藏', color: '#999999' }
  },
  ISPUBLISH_STATE_OPTIONS: [
    { value: 1, label: '已发布' },
    { value: 0, label: '未发布' }
  ],
  ISPUBLISH_STATE_CONTENT: {
    '0': { text: '未发布', color: '#333333', icon: 'el-icon-warning', iconColor: '#DE504E' },
    '1': { text: '已发布', color: '#333333', icon: 'el-icon-success', iconColor: '#50D4AB' }
  },
  APP_TYPE_OPTIONS: [
    // { value: 0, label: '默认' },
    { value: 1, label: '运营' },
    { value: 2, label: '管理' },
    { value: 3, label: '服务' },
    { value: 4, label: '办公' },
    { value: 5, label: '集控平台' }
  ],
  APP_TYPE_CONTENT: {
    '0': { text: '默认', color: '#333333' },
    '1': { text: '运营', color: '#333333' },
    '2': { text: '管理', color: '#333333' },
    '3': { text: '服务', color: '#333333' },
    '4': { text: '办公', color: '#333333' },
    '5': { text: '集控平台', color: '#333333' }
  }
}
export default wtConst
