import wtRequest from '@/networks/wtRequest'
import wtLayoutConfig from '@/wtLayoutConfig'
import wtTheme from '@/utils/wtTheme'

const SESSION_WT_THEME = 'WT:THEME'
let sessionTheme = null
try {
  console.log('sessionTheme init')
  sessionTheme = Object.assign({}, wtLayoutConfig, JSON.parse(sessionStorage.getItem(SESSION_WT_THEME) || '{}'))
  wtTheme.adaptationTheme(sessionTheme.layoutStyle)
} catch (e) {
}

const app = {
  state: {
    dictCacheMap: {},
    theme: sessionTheme || {},
    sidebar: {
      opened: true
    },
    menuNameList: [],
    mainMenuName: '',
    subMenuName: '',
    currentRoute: {}
  },
  mutations: {
    UPDATE_THEME: (state, data) => {
      state.theme = Object.assign({}, state.theme, data)
      sessionStorage.setItem(SESSION_WT_THEME, JSON.stringify(state.theme))
    },
    UPDATE_CURRENT_ROUTE: (state, data) => {
      state.currentRoute = data
      state.mainMenuName = (data && data.matched && data.matched.length > 0) ? data.matched[0].name : ''
      state.subMenuName = (data && data.name) ? data.name : ''
      if (data && data.matched && data.matched.length > 0) {
        data.matched.forEach(item => {
          if (state.menuNameList.indexOf(item.name) === -1) {
            state.menuNameList.push(item.name)
          }
        })
      }
    },
    TOGGLE_MENU: (state, { name }) => {
      if (state.menuNameList.indexOf(name) === -1) {
        state.menuNameList.push(name)
      } else {
        state.menuNameList.splice(state.menuNameList.indexOf(name), 1)
      }
    },
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened
    },
    UPDATE_DICT_DATA: (state, data) => {
      state.dictCacheMap[data.dictKey] = data.list
    }
  },
  actions: {
    updateTheme: ({ commit }, data) => {
      commit('UPDATE_THEME', data)
    },
    updateCurrentRoute: ({ commit }, data) => {
      commit('UPDATE_CURRENT_ROUTE', data)
    },
    toggleMenu: ({ commit }, data) => {
      commit('TOGGLE_MENU', data)
    },
    toggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    queryDictList({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        if (state.dictCacheMap[data.dictKey] && state.dictCacheMap[data.dictKey].length > 0) {
          resolve(state.dictCacheMap[data.dictKey])
          return
        }
        wtRequest({
          url: '/dict/ajaxList',
          method: 'post',
          data: { 'dictKey': data.dictKey }
        }).then(resp => {
          commit('UPDATE_DICT_DATA', {
            dictKey: data.dictKey,
            list: resp.data
          })
          resolve(resp.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default app
