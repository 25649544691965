<template>
  <div class="page-tree-container">
    <div class="page-left" v-show="isLeftVisible">
      <slot name="left"></slot>
    </div>
    <div class="page-right">
      <slot name="right"></slot>
    </div>
    <div class="page-dropbutton" @click="onClickDropButton" :style="{left: isLeftVisible ? '250px' : '20px'}">
      <i class="el-icon-caret-left" v-if="isLeftVisible"></i>
      <i class="el-icon-caret-right" v-else></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wtTreeLayout',
  isAutoComponent: true,
  data() {
    return {
      isLeftVisible: true
    }
  },
  methods: {
    onClickDropButton() {
      this.isLeftVisible = !this.isLeftVisible
    }
  }
}
</script>

<style lang="scss" scoped>
.page-tree-container{height: 100%; width: 100%;display: flex;flex-direction: row;position: relative;}
.page-tree-container .page-left{width: 250px;padding: 0;margin: 0;background-color: #FFFFFF;}
.page-tree-container .page-dropbutton{
  width: 16px;
  height: 60px;
  position: absolute;
  left: 220px;
  top: calc(50% - 30px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  opacity: .7;
  border-radius: 0 6px 6px 0;
  background: linear-gradient(90deg, #5F6C83, #374358);
  i {
    color: #fff;
  }
}.page-tree-container .page-dropbutton:active{opacity: 0.75;}
.page-tree-container .page-right{flex: 1;overflow-y: auto;}
</style>
