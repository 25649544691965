<template>
  <i class="wt-user-fullscreen el-icon-full-screen" title="切换全屏" @click="$wtUtil.toggleFullscreen()"></i>
</template>

<script>
export default {
  name: 'WtUserFullscreen',
  methods: {
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.wt-user-fullscreen {
  cursor: pointer;
  margin-left: 20px;
  font-size: 16px;
  text-align: center;
}
</style>
