<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  request-list-url="/sysMenuGroup/list">
      <template slot="search">
        <wt-search-form-item title="菜单方案名称" class="wt-col-6" v-model="form.formData.username" type="input" />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['SYSUSER:ADD']" @click="onClickAdd">新建菜单方案</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="menuGroupName" label="菜单方案名称"></el-table-column>
        <el-table-column prop="roleNames" label="绑定角色" min-width="100"></el-table-column>
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.ISPUBLISH_ENUM_CONTENT[scope.row.isPublish]"></wt-enum-content></template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['SYSUSER:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['SYSUSER:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish == 0 && scope.row.isDefault != 1" v-has-any-premission="['SYSUSER:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-page-dialog ref="editDrawer" title="账号详情">
      <user-detail :detail-data="form.detailData" :view-type="form.dialogViewType"
                   @on-close="$refs.editDrawer.close()" @on-refresh="$refs.dataForm.requestDataList()"></user-detail>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'menuList',
  components: {},
  data() {
    return {
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        table: { flag: 'result' },
        formData: {
          createTimeRange: [],
          realname: '',
          username: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    onClickView(rowData) {
      this.form.dialogViewType = 'view'
      this.form.detailData = rowData
      this.$refs.editDrawer.open()
    },
    onClickEdit(rowData) {
      this.form.dialogViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.editDrawer.open()
    },
    onClickAdd() {
      this.form.dialogViewType = 'add'
      this.$refs.editDrawer.open()
    }
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
