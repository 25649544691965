<template>
  <div v-if="$wtUtil.isNotBlank(title)" class="wt-section">
    <div class="wt-section-title">
      <span>{{title}}</span>
      <div class="wt-section-title-icon" v-if="isExpandedEnable" @click="isExpanded = !isExpanded"><i :class="{'el-icon-caret-right': !isExpanded, 'el-icon-caret-bottom': isExpanded}"></i></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-section',
  props: {
    title: { type: String, default: '' },
    isExpandedEnable: { type: Boolean, default: false },
    isExpanded: { type: Boolean, default: false }
  }
}
</script>

<style scoped lang="scss">
.wt-section{height: 40px;border-bottom: 1px solid #F0F0F0;display: flex;align-items: center;}
.wt-section-title{margin-left: 20px;}
.wt-section-title-icon{}
</style>
