<template>
  <div class="wt-top-layout wt-top-layout-wrapper">
    <div class="wt-layout-header wt-fixed-header wt-top-layout-fixed-header">
      <div class="wt-layout-header wt-fixed-header wt-top-fixed-header">
        <div class="wt-nav">
          <div class="wt-logo-container wt-top-logo-container">
            <div class="wt-logo">LOGO</div>
            <div class="wt-logo-title">{{appName}}</div>
          </div>
          <div class="wt-nav-right-panel">
            <wt-user-switch></wt-user-switch>
            <wt-user-fullscreen></wt-user-fullscreen>
            <wt-user-panel class="wt-user-panel-wrapper"></wt-user-panel>
          </div>
        </div>
      </div>
    </div>
    <div class="wt-main-container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WtUserFullscreen from '../../userPanel/WtUserFullscreen'
import WtUserSwitch from '../../userPanel/WtUserSwitch'
import WtUserPanel from '../../userPanel/WtUserPanel'
export default {
  name: 'WtTopLayout',
  data() {
    return {
      activeIndex: '',
      appName: process.env.VUE_APP_NAME
    }
  },
  components: {
    WtUserFullscreen,
    WtUserSwitch,
    WtUserPanel
  },
  methods: {
    onClickFold() {
      this.$store.dispatch('toggleSideBar')
    }
  },
  computed: {
    ...mapState({
      theme: state => state.app.theme,
      mainMenuName: state => state.app.mainMenuName,
      subMenuName: state => state.app.subMenuName
    }),
    ...mapGetters([
      'sidebar'
    ])
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/variables.scss";
  .wt-top-layout-wrapper{
    padding-top: 48px;
    .wt-top-fixed-header{
      width: 100%;
      background-color: #3b50a6;
      .wt-nav{
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .wt-logo-container{
        color: #FFFFFF;
        margin-left: 32px;
      }
      .wt-nav-right-panel {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: flex-end;
        color: #FFFFFF;
        margin-right: 32px;
        height: 100%;
        .wt-nav-icon {
          cursor: pointer;
          margin-left: 20px;
          font-size: 16px;
          text-align: center;
        }
      }
      .wt-user-panel-wrapper{
        color: #FFFFFF;
      }
    }

    .wt-main-container{

    }
  }
</style>
