import Vue from 'vue'
import filters from '@/filters/filters'
/*  插件 FILTER 开始 */
Object.keys(filters).forEach(key => {
  Vue.filter(key, (value) => {
    return filters[key][value + ''] || filters[key]['default'] || '-'
  })
})

export default {}
