<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  request-list-url="/rtAppFile/list" request-delete-url="/rtAppFile/delete" request-detail-url="/rtAppFile/detail">
      <template slot="search">
        <wt-search-form-item title="应用名称" v-model="form.formData.appName" type="input"/>
        <wt-search-form-item title="分类" v-model="form.formData.appType" type="select" :dataList="$wtConst.APP_TYPE_OPTIONS"/>
        <wt-search-form-item title="状态" v-model="form.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH_ENUM_OPTIONS"/>
        <!-- <wt-search-form-item title="创建时间" width="col-3" v-model="form.formData.createTimeRange" type="datetimerange" /> -->
      </template>
      <template slot="toolbar">
        <!-- <wt-button type="primary" v-has-any-premission="['RTAPPFILE:ADD']" @click="onClickAdd">新增</wt-button> -->
        <wt-button type="primary" @click="onClickAdd">新增应用</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="appName" label="应用名称"></el-table-column>
        <el-table-column prop="appType" label="分类">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.APP_TYPE_CONTENT[scope.row.appType]"></wt-enum-content></template>
        </el-table-column>
        <!-- <el-table-column prop="appSkipUrl" label="跳转路径"></el-table-column>
        <el-table-column prop="fileUrl" label="应用图标路径"></el-table-column>
        <el-table-column prop="fileType" label="应用图标文件格式"></el-table-column>
        <el-table-column prop="createUserId" label="创建人ID"></el-table-column> -->
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="username" label="创建人"></el-table-column>
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.ISPUBLISH_ENUM_CONTENT[scope.row.isPublish]"></wt-enum-content></template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-if="scope.row.isPublish === 0" @click="onClickPublish(scope.row)">启用</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 1" @click="onClickPublish(scope.row)">禁用</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 1" @click="onClickView(scope.row)">详情</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 0" @click="onClickEdit(scope.row)">修改</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 0" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button>
            <!-- <wt-text-button v-has-any-premission="['RTAPPFILE:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['RTAPPFILE:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.isDefault == 0" v-has-any-premission="['RTAPPFILE:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button> -->
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
     <wt-page-dialog ref="detailDialog" title="应用表详情">
       <rtAppFile-detail :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.detailDialog.close()"></rtAppFile-detail>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'rtAppFileList',
  data() {
    return {
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {},
        pageData: { currentPage: 1, pageSize: 20, sortName: 'createTime', sortOrder: 'desc' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    onClickPublish(rowData) {
      const that = this
      const isPublish = rowData.isPublish === 0 ? '启用' : '禁用'
      this.$confirm('是否' + isPublish + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.form.isFromLoading = true
        that.$wtRequest({
          url: '/rtAppFile/is_publish',
          method: 'post',
          data: {
            id: rowData.id,
            isPublish: rowData.isPublish === 0 ? 1 : 0
          }
        }).then((resp) => {
          if (resp.isSuccess) {
            that.$message({ type: 'success', message: isPublish + '成功' })
            that.$refs.dataForm.requestDataList()
          } else {
            that.form.isFromLoading = false
          }
        }).catch(() => {
          that.form.isFromLoading = false
        })
      }).catch(() => {})
    },
    onClickView(rowData) {
      this.form.detailViewType = 'view'
      this.form.detailData = rowData
      this.$refs.detailDialog.open()
    },
    onClickEdit(rowData) {
      this.form.detailViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.detailDialog.open()
    },
    onClickAdd() {
      this.form.detailViewType = 'add'
      this.$refs.detailDialog.open()
    },
    onClickDelete(rowData) {
      this.$refs.dataForm.onClickDelete(rowData.id)
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
