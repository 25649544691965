<template>
  <div class="wt-page-section">
    <div class="header" v-if="title">
      <span class="title">{{title}}</span>
      <slot name="title-right" ></slot>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WtPageSection',
  props: {
    title: { type: String, default: '' }
  },
  data() {
    return {
      isExpanded: true
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.wt-page-section{background-color: transparent;width: calc(100% - 40px);margin: 20px 20px 16px;border-radius: 4px;color: #FFFFFF;}
.wt-page-section > .header{height: 50px;width: 100%;border-bottom: 1px solid #F0F0F0;color: #333333;display: flex;align-items: center;}
.wt-page-section > .header .title {margin-left: 16px;font-size: 16px;font-weight: bold;}
.wt-page-section > .header .title-right{flex: 1;height: 100%;display: flex;justify-content: flex-end;}
.wt-page-section > .content{}
</style>
