<template>
  <el-drawer class="wt-drawer" :direction="direction" :size="size"
             :visible.sync="isVisible" :withHeader="false" append-to-body destroy-on-close >
    <slot name="header"></slot>
    <slot></slot>
  </el-drawer>
</template>

<script>
export default {
  name: 'WtDrawer',
  isAutoComponent: true,
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'rtl'
    },
    size: {
      type: [Number, String],
      default: 700
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  mounted() {
  },
  methods: {
    open() {
      this.isVisible = true
    },
    close() {
      this.isVisible = false
    }
  },
  computed: {
  }
}
</script>

<style scoped>
</style>
