<template>
  <div class="page-container">
    <div class="wt-permission-container" v-loading="isFromLoading">
      <permission-item v-if="!item.hidden" :key="'permission_' + index" v-for="(item, index) in staticRoutes"
                       :item="item" :level="0" :module-permission-map="modulePermissionMap" :checkedPermissionCodes="checkedPermissionCodes"
                       @on-delete-custom="onDeleteCustomPermission"
                       @on-create-custom="onCreateCustomPermission"></permission-item>
    </div>
    <!--弹层 开始-->
    <wt-page-dialog ref="editDrawer" title="权限信息">
      <permission-detail :detail-data="form.detailData" :view-type="form.dialogViewType"
                       @on-close="$refs.editDrawer.close()" @on-refresh="onRefresh"/>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import staticRoutes from '@/router/staticRoutes.js'

export default {
  name: 'permissionList',
  data() {
    return {
      staticRoutes: staticRoutes,
      isShowCode: true,
      isFromLoading: false,
      modulePermissionMap: {},
      checkedPermissionCodes: [],
      form: {
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        table: {},
        formResult: {}
      }
    }
  },
  mounted() {
    this.requestPermissionList()
  },
  methods: {
    onCreateCustomPermission(data) {
      this.form.detailData = data
      this.form.dialogViewType = 'add'
      this.$refs.editDrawer.open()
    },
    onDeleteCustomPermission(permissionId) {
      const that = this
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.requestPermissionDelete(permissionId)
      }).catch(() => {
      })
    },
    onRefresh() {
      this.requestPermissionList()
    },
    requestPermissionList() {
      const that = this
      that.$wtRequest({
        url: '/auth/permissionList',
        method: 'post',
        data: {}
      }).then((resp) => {
        const tempMap = {}
        resp.data.forEach(item => {
          if (!tempMap[item.moduleName]) {
            tempMap[item.moduleName] = []
          }
          tempMap[item.moduleName].push(item)
        })
        that.modulePermissionMap = tempMap
      }).catch(() => {
      })
    },
    requestPermissionDelete(id) {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/auth/permissionDelete',
        method: 'post',
        data: { list: [id] }
      }).then((resp) => {
        that.isFromLoading = false
        that.requestPermissionList()
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .wt-permission-container{width: calc(100% - 40px);margin: 20px;background-color: white;border-bottom: 1px solid #F4F4F4;border-radius: 4px;}
</style>
