<template>
  <div class="wt-drawer-container">
    <div class="wt-drawer-body" v-loading="isFromLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
      <el-form :model="detailForm" ref="form" size="small" class="wt-detail-form" style="margin-top: 16px;">
        <el-form-item class="wt-detail-form-item" label="用户名" prop="username">
          {{userInfo.username}}
        </el-form-item>
        <el-form-item class="wt-detail-form-item">
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="用户姓名" prop="realname" :rules="[ { required: true, message: '用户姓名不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' } ]">
          <el-input placeholder="请输入" v-model="detailForm.realname"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="电话号码" prop="mobile" :rules="[ { required: true, message: '电话号码不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' } ]">
          <el-input placeholder="请输入" v-model="detailForm.mobile"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="旧密码" prop="password" :rules="[ { required: true, message: '旧密码不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' } ]">
          <el-input placeholder="请输入" type="password" v-model="detailForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="新密码" prop="newPassword" :rules="[ { required: true, message: '新密码不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' } ]">
          <el-input placeholder="请输入" type="password" v-model="detailForm.newPassword" show-password></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="确认密码" prop="confirmPassword" :rules="[ { required: true, message: '确认密码不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' } ]">
          <el-input placeholder="请输入" type="password" v-model="detailForm.confirmPassword" show-password></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="wt-drawer-footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" :loading="isButtonLoading"  v-has-any-premission="['SYSUSER:UPDATE']" @click="onClickSave">保存</wt-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'WtAccountUpdate',
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {
        username: '',
        password: '',
        realname: '',
        mobile: '',
        newPassword: '',
        confirmPassword: ''
      }
    }
  },
  mounted() {
    this.requestPersonalDetail()
  },
  methods: {
    onClickClose() {
      this.$emit('on-close')
    },
    onClickSave() {
      const that = this
      const newPassword = that.detailForm.newPassword
      const confirmPassword = that.detailForm.confirmPassword
      if (that.$wtUtil.isBlank(newPassword) || that.$wtUtil.isBlank(confirmPassword)) {
        this.$message({ type: 'error', message: '密码和确认密码不能为空' })
        return
      }
      if (newPassword !== confirmPassword) {
        console.log(newPassword, confirmPassword)
        this.$message({ type: 'error', message: '密码和确认密码必须相同' })
        return
      }
      that.isButtonLoading = true
      that.$wtRequest({
        url: '/auth/personal/update',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        if (resp.code !== '0') {
          return
        }
        that.$message({ type: 'success', message: '更新用户信息成功' })
        setTimeout(() => {
          that.isButtonLoading = false
          that.$emit('on-close')
        }, 1000)
      }).catch(() => {
        that.$message({ type: 'error', message: '更新用户信息失败，请稍后再试' })
        that.isButtonLoading = false
      })
    },
    requestPersonalDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/auth/personal/detail',
        method: 'post',
        data: {}
      }).then((resp) => {
        if (resp.code !== '0') {
          return
        }
        that.isFromLoading = false
        that.detailForm.realname = resp.data.realname
        that.detailForm.mobile = resp.data.mobile
      }).catch(() => {
        that.$message({ type: 'error', message: '获取用户信息失败，请稍后再试' })
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    })
  }
}
</script>
