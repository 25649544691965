export default {
  /**
   * 布局模式
   */
  name: 'default',
  /**
   * 整体风格
   */
  layoutStyle: 'dark',
  /*
   * 是否显示菜单图标
   */
  isMenuIconVisible: true,
  /**
   * 标签栏开关
   */
  isTabEnable: false,
  /**
   * 是否显示标签图标
   */
  isTabIconVisible: false,
  /**
   * 面包屑开关
   */
  isBreadcrumbEnable: true,
  /**
   * 是否显示面包屑图标
   */
  isBreadcrumbIconVisible: false,
  /**
   * 权限按钮开关
   */
  isAuthEnable: true,
  /**
   * 静态菜单开关
   * 打开 表示加载@/router/staticRoutes.js中的菜单配置
   * 关闭 表示通过后台接口(/authInfo)加载菜单配置
   */
  isStaticRouter: false,
  /**
   * 多菜单方案开关
   * 打开 表示角色可以指定菜单方案
   * 关闭 表示角色不能指定菜单方案，默认方案生效
   */
  isMultipleMenuGroup: true
}
