<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'WtBlankLayout'
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
