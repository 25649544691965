<template>
  <div>
    <el-result icon="error" title="错误提示" subTitle="请根据提示进行操作">
      <template slot="extra">
        <el-button type="primary" size="medium">返回</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
