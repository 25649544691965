/*
  全局过期器定义
  注意：1. 使用字符类型表示key值；2. 使用'default'表示默认值；3.当没有定义'default'值时，将返回空字符串
 */
const filters = {
  FILTER_YesOrNo: {
    '0': '否',
    '1': '是',
    'default': '-'
  }
}

export default filters
