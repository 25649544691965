<script type="text/jsx">
export default {
  name: 'WtFormText',
  isAutoComponent: true,
  props: {
    name: String,
    label: String,
    colSize: Number
  },
  data() {
    return {
      text: ''
    }
  },
  mounted() {},
  methods: {},
  computed: {},
  render(h) {
    console.log('WtFormText injections', this.injections)
    return (<div class='wt-form-text'>
      { this.label && <span>{ this.label }</span> }
      <el-input v-model={this.text}></el-input>
    </div>)
  }
}
</script>
<style lang="scss" scoped>
  .wt-form-text{}
</style>
