<template>
  <div class="wt-column-layout" :class="{'is-tabs-hidden': !theme.isTabEnable}">
    <div class="wt-silder" :class="{'is-silder-close': !sidebar.opened}">
      <div class="wt-silder-scroller wt-scroll">
        <div class="wt-logo-container wt-column-logo-container">
          <div class="wt-logo">LOGO</div>
          <div class="wt-logo-title">{{appName}}</div>
        </div>
        <div class="wt-menu-main-wrapper">
          <div class="wt-menu-main">
            <div class="wt-menu-scroll wt-scroll">
              <div class="wt-menu-item-wrapper" v-for="(routeItem, routeIndex) in moduleList" :key="routeIndex" v-if="!(routeItem.meta && routeItem.meta.hidden)"
                   :class="{'is-active': mainMenuName == routeItem.name}" >
                <div class="wt-main-menu-item" @click="onClickMainMenu(routeItem)">
                  <div class="wt-menu-item-body">
                    <i v-show="theme.isMenuIconVisible" :class="routeItem.meta && routeItem.meta.icon"></i><span>{{routeItem.meta && routeItem.meta.title}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wt-menu-sub">
          <div class="wt-menu-sub-gap"></div>
          <ul class="wt-menu" v-for="(routeItem, routeIndex) in moduleList" :key="routeIndex" v-if="mainMenuName == routeItem.name">
            <wt-menu-item v-if="subItem.meta && !subItem.meta.hidden" v-for="(subItem, subIndex) in routeItem.children"  :key="'subIndex_'+subIndex" :data="subItem" :level="1"></wt-menu-item>
          </ul>
        </div>
      </div>
    </div>
    <div id="wt-main" class="wt-main" :class="{'is-silder-close': !sidebar.opened}">
      <div class="wt-layout-header wt-fixed-header wt-column-fixed-header">
        <div class="wt-nav">
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="wt-nav-left-panel">
                <i class="wt-fold" :class="[sidebar.opened ? 'el-icon-s-fold' : 'el-icon-s-unfold']" @click="onClickFold"></i>
                <wt-breadcrumb v-if="theme.isBreadcrumbEnable" />
              </div>
            </el-col>
            <el-col :span="12">
              <div class="wt-nav-right-panel">
                <wt-user-switch></wt-user-switch>
                <wt-user-fullscreen></wt-user-fullscreen>
                <wt-user-panel></wt-user-panel>
              </div>
            </el-col>
          </el-row>
        </div>
        <wt-tabs v-if="theme.isTabEnable" />
      </div>
      <div id="wt-app-main" class="wt-app-main" :class="{'is-tabs-hidden': !theme.isTabEnable}">
        <section>
          <router-view></router-view>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WtMenuItem from '../../menu/WtMenuItem'
import WtBreadcrumb from '../../breadcrumb/WtBreadcrumb'
import WtTabs from '../../tabs/WtTabs'
import WtUserFullscreen from '../../userPanel/WtUserFullscreen'
import WtUserSwitch from '../../userPanel/WtUserSwitch'
import WtUserPanel from '../../userPanel/WtUserPanel'
export default {
  name: 'WtColumnLayout',
  data() {
    return {
      appName: process.env.VUE_APP_NAME
    }
  },
  components: {
    WtTabs,
    WtBreadcrumb,
    WtMenuItem,
    WtUserFullscreen,
    WtUserSwitch,
    WtUserPanel
  },
  created() {
  },
  mounted() {
  },
  methods: {
    onClickFold() {
      this.$store.dispatch('toggleSideBar')
    },
    onClickMainMenu(item) {
      const itemNotHiddenChildren = (item.children || []).filter(item => item.meta && !item.meta.hidden)
      const findFirtChildren = (temp) => {
        const notHiddenChildren = (temp.children || []).filter(item => item.meta && !item.meta.hidden)
        if (notHiddenChildren.length > 0) {
          return findFirtChildren(notHiddenChildren[0])
        }
        return temp
      }
      if (itemNotHiddenChildren.length > 0) {
        this.$router.push({ name: findFirtChildren(item).name })
      } else {
        this.$router.push({ path: item.path })
      }
    }
  },
  computed: {
    ...mapState({
      theme: state => state.app.theme,
      mainMenuName: state => state.app.mainMenuName,
      subMenuName: state => state.app.subMenuName,
      moduleList: state => state.auth.moduleList
    }),
    ...mapGetters([
      'sidebar'
    ])
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/variables.scss";

  .wt-column-layout {
    padding-top: 110px;
    &.is-tabs-hidden {
      padding-top: 60px;
    }
  }

  .wt-column-logo-container {
    .wt-logo {
      background: #282c34;
      position: fixed;
      top: 0;
      display: block;
      width: 64px;
      height: 60px;
      margin: 0;
      color: #FFFFFF;
    }
    .wt-logo-title {
      color: $--wt-color-text-primary !important;
      padding-right: 15px;
      padding-left: 15px;
      margin-left: 64px !important;
      background: #fff !important;
      display: inline-block;
      margin-left: 5px;
      overflow: hidden;
      font-size: 20px;
      line-height: 55px;
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }
  }

  .wt-menu-main-wrapper {
    position: fixed;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    background: #282c34;
    width: 64px;
    height: calc(100% - 60px);
    color: #FFFFFF;
    overflow: hidden;

    .wt-menu-main {
      height: 100%;

      .wt-menu-scroll {
        height: 100%;
        overflow-y: auto;
      }

      .wt-menu-scroll::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .wt-menu-item-wrapper {
        padding: 5px;
      }

      .wt-menu-item-wrapper {
      }

      .wt-main-menu-item {
        border-radius: 5px;
        cursor: pointer;
        height: 54px;
        width: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
        transition: all .3s cubic-bezier(.645, .045, .355, 1), border 0s, color .1s, font-size 0s;
      }

      .wt-menu-item-wrapper.is-active .wt-main-menu-item {
        background: $--color-primary;
      }

      .wt-menu-item-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .wt-menu-item-body i {
        height: 20px;
      }
    }
  }

  .wt-menu-sub {
    left: 74px;
    width: 182px;
    position: relative;

    .wt-menu-sub-gap {
      margin: 0 0 20px 0;
      height: 1px;
      border-top: 1px solid #F0F0F0;
    }
  }

  .wt-column-fixed-header {
    transition: all .3s cubic-bezier(.645, .045, .355, 1), border 0s, color .1s, font-size 0s;
    width: calc(100% - 266px);
    .wt-nav {
      position: relative;
      height: $--wt-header-height;
      padding-right: 20px;
      padding-left: 20px;
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: #FFFFFF;
      box-shadow: 0 1px 4px rgb(0 21 41 / 8%);

      .wt-nav-left-panel {
        display: flex;
        align-items: center;
        justify-items: center;
        height: $--wt-header-height;
      }
      .wt-nav-right-panel {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: flex-end;
        height: $--wt-header-height;
        color: #606266;
        .wt-nav-icon {
          cursor: pointer;
          margin-left: 20px;
          font-size: 16px;
          text-align: center;
        }
      }
    }

    .wt-tabs {
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      min-height: 50px;
      padding-right: 20px;
      padding-left: 20px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: #FFFFFF;
      border-top: 1px solid #f6f6f6;
    }
  }

</style>

<style lang="scss">
  @import "@/styles/variables.scss";
  .wt-column-layout {
    .wt-main {
      margin-left: 266px;
    }
    .wt-menu {
      .wt-menu-item, .wt-sub-menu-title {
        display: flex;
        align-items: center;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        color: #333333;
        padding: 0 20px;
        list-style: none;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        white-space: nowrap;
        min-width: 180px;
        margin-bottom: 5px;
        border-radius: 5px;
        i+span{
          margin-left: 4px;
        }
      }
    }
    .wt-menu-item.is-active, .wt-menu-item:hover, .wt-sub-menu-title:hover{
      background-color: $--wt-color-primary-light;
      i, i+span{
        color: $--wt-color-primary;
      }
    }
    .wt-sub-menu-title{
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      position: relative;
      .wt-sub-menu-arrow{
        position: absolute;
        top: 50%;
        right: 20px;
        margin-top: -7px;
        font-size: 12px;
        margin-right: 0;
        width: inherit;
      }
    }

    .wt-sub-menu.is-opened {
      > .wt-sub-menu-title > .wt-sub-menu-arrow {
        transform: rotate(180deg);
      }
    }
  }
</style>
