<template>
  <wt-page v-loading="isFromLoading" :is-footer-visiable="false">
    <div class="wt-drawer-container">
      <div class="wt-section"><span>菜单</span></div>
      <el-form :model="detailForm" ref="form" class="wt-page-form" >
        <el-form-item class="wt-detail-form-item" label="父菜单">
          <el-input placeholder="请输入" v-model="parentData.menuTitle" disabled maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="菜单标题" prop="menuTitle"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-tooltip class="wt-label-tip"><div slot="content">在菜单栏、标签栏显示的内容</div><i class="el-icon-info"></i></el-tooltip>
          <el-input placeholder="请输入" v-model="detailForm.menuTitle" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="菜单路径" prop="menuPath"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-tooltip class="wt-label-tip"><div slot="content">在浏览器地址栏显示的内容，当存在父节点时，会拼接在父节点后面</div><i class="el-icon-info"></i></el-tooltip>
          <el-input placeholder="请输入" v-model="detailForm.menuPath" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="菜单组件类型" prop="menuComponentType"
                      :rules="[{ required: true, message: '请选择菜单组件类型', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]" >
          <el-select placeholder="请输入" v-model="detailForm.menuComponentType" :disabled="viewType == 'view'" @change="onMenuComponentTypeChange">
            <el-option :label="MENUCOMPONENTTYPE_ENUM.ROOT.NAME" :value="MENUCOMPONENTTYPE_ENUM.ROOT.KEY" v-if="!parentData.id || parentData.id == ''"></el-option>
            <el-option :label="MENUCOMPONENTTYPE_ENUM.GROUP.NAME" :value="MENUCOMPONENTTYPE_ENUM.GROUP.KEY"></el-option>
            <el-option :label="MENUCOMPONENTTYPE_ENUM.PAGE.NAME" :value="MENUCOMPONENTTYPE_ENUM.PAGE.KEY"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="菜单组件" prop="menuComponent" v-if="detailForm.menuComponentType == MENUCOMPONENTTYPE_ENUM.PAGE.KEY">
          <el-tooltip class="wt-label-tip">
            <div slot="content">
              <div>页面节点填写组件路径，如@/modules/system/userList</div>
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
          <el-input placeholder="请输入" v-model="detailForm.menuComponent" :disabled="viewType == 'view'" maxlength="255">
            <template slot="prepend">@/modules/</template>
            <template slot="append">.vue</template>
          </el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="菜单标示" prop="menuName"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-tooltip class="wt-label-tip"><div slot="content">菜单唯一标示，推荐使用数字或下划线或英文大写</div><i class="el-icon-info"></i></el-tooltip>
          <el-input placeholder="请输入" v-model="detailForm.menuName" :disabled="viewType == 'view'" maxlength="255" @blur="onMenuNameBlur"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="菜单图标" prop="menuIcon"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input placeholder="请输入" v-model="detailForm.menuIcon" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="是否在菜单隐藏" prop="isHidden"
                      :rules="[{ required: true, message: '请选择是否在菜单隐藏', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
          <el-switch v-model="detailForm.isHidden" :active-value="1" :inactive-value="0" :disabled="viewType == 'view'"></el-switch>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="是否在标签栏隐藏" prop="isHiddenInTab"
                      :rules="[{ required: true, message: '请选择是否在标签栏隐藏', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
          <el-switch v-model="detailForm.isHiddenInTab" :active-value="1" :inactive-value="0" :disabled="viewType == 'view'"></el-switch>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="菜单跳转路径" prop="menuRedirect" >
          <el-input placeholder="请输入" v-model="detailForm.menuRedirect" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="排序" prop="orderNum"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-tooltip class="wt-label-tip">
            <div slot="content">
              <div>请输入正整数，数字大的菜单排序更靠前</div>
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
          <el-input placeholder="请输入" type="number" v-model="detailForm.orderNum" :disabled="viewType == 'view'" maxlength="10"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="wt-footer">
      <el-button @click="onClickClose">关闭</el-button>
      <el-button type="primary" @click="onClickSave">保存</el-button>
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'
import { MENUCOMPONENTTYPE_ENUM } from '../constants/helper'
export default {
  name: 'menuDetail',
  props: {
    detailData: {
      default: () => {}
    },
    parentData: {
      default: () => {}
    },
    viewType: {
      default: ''
    }
  },
  data() {
    return {
      MENUCOMPONENTTYPE_ENUM: MENUCOMPONENTTYPE_ENUM,
      isMenuIconVisible: false,
      form: {
        formResult: {
          list: [{}, {}]
        },
        table: {
          emptyText: '暂无数据'
        }
      },
      isFromLoading: false,
      detailForm: {},
      roleList: []
    }
  },
  mounted() {
    this.detailForm = Object.assign({}, this.detailData)
  },
  methods: {
    onClickClose() {
      this.$emit('on-close')
    },
    onClickSave() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.$emit('on-save', that.detailForm)
      }).catch(() => {
      })
    },
    onMenuComponentTypeChange(val) {
      this.detailForm.menuComponent = MENUCOMPONENTTYPE_ENUM[val].DEFAULT_MENUCOMPONENT
    },
    onMenuNameBlur(val) {
      this.detailForm.menuName = this.detailForm.menuName && this.detailForm.menuName.toUpperCase()
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
</style>
