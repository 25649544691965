<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  request-list-url="/sysLog/list">
      <template slot="search">
        <wt-search-form-item title="用户名" width="col-3" v-model="form.formData.username" type="input" />
        <wt-search-form-item title="用户姓名" width="col-3" v-model="form.formData.realname" type="input" />
        <wt-search-form-item title="操作时间" width="col-3" v-model="form.formData.createTimeRange" type="datetimerange" />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['SYSGLOBALLOG:EXPORT']">导出</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="createTime" label="操作时间"></el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="realname" label="用户姓名"></el-table-column>
        <el-table-column prop="logModule" label="操作模块"></el-table-column>
        <el-table-column prop="logContent" label="操作内容" min-width="200"></el-table-column>
        <el-table-column prop="logIp" label="IP地址" width="120"></el-table-column>
      </template>
    </wt-data-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'sysLogList',
  components: {},
  data() {
    return {
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        table: { flag: 'result' },
        formData: {
          createTimeRange: [],
          realname: '',
          username: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
