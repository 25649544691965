const vueModules = []
const modules = require.context('@/modules', true, /.vue$/)
modules.keys().forEach(key => {
  const module = modules(key).default
  if (module && module.isAutoComponent) {
    vueModules.push(module)
  }
})
export default {
  ...vueModules
}
