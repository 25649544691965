import wtUtil from '@/utils/wtUtil'

const wtValidator = {
  ValidatorNotBlank: (rule, value, callback) => {
    if (wtUtil.isBlank(value)) {
      callback(new Error(''))
    } else {
      callback()
    }
  },
  ValidatorNotEmpty: (rule, value, callback) => {
    if (!value || !value.length || value.length <= 0) {
      callback(new Error(''))
    } else {
      callback()
    }
  },
  ValidatorWtUpload: (rule, value, callback) => {
    if (!value || value.length <= 0) {
      const text = rule.name ? `请上传${rule.name}` : '请上传文件'
      return callback(new Error(text))
    }
    if (rule && value.length > rule.limit) {
      return callback(new Error(`最多上传${rule.limit}个文件`))
    }
    return callback()
  },
  ValidatorWtEditor: (rule, value, callback) => {
    if (wtUtil.isBlank(value)) {
      callback(new Error(''))
    } else {
      callback()
    }
  }
}

export default wtValidator
