<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <el-form :model="detailForm" ref="form" class="wt-page-form" label-width="120px" size="small">
        <el-form-item label="所属模块：">
          <el-input :value="detailData.meta.title" placeholder="请输入" disabled></el-input>
        </el-form-item>
        <el-form-item label="权限名称：" prop="permissionName" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input v-model="detailForm.permissionName" maxlength="20" placeholder="请输入中文名称"></el-input>
        </el-form-item>
        <el-form-item label="权限码值：" prop="permissionCode" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input v-model="detailForm.permissionCode" maxlength="20" placeholder="请输入英文编码">
            <template slot="prepend">{{detailData.name.toUpperCase()}}:</template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['SYSPERMISSION:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['SYSPERMISSION:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
</template>

<script>
export default {
  name: 'permissionDetail',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
      }
    }
  },
  mounted() {
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    onClickClose() {
      this.$emit('on-close')
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/auth/permissionDetail',
        method: 'post',
        data: { moduleName: that.detailData.name, permissionCode: that.permissionCode }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      const moduleList = that.$refs.moduleTree.getCheckedNodes(false, true)
      const moduleIdList = []
      moduleList.forEach((module) => {
        moduleIdList.push(module.id)
      })
      that.detailForm.moduleList = moduleIdList
      that.$wtRequest({
        url: '/auth/permissionUpdate',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/auth/permissionAdd',
        method: 'post',
        data: {
          moduleName: that.detailData.name,
          permissionName: that.detailForm.permissionName,
          permissionCode: that.detailForm.permissionCode
        }
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    }
  },
  computed: {
  }
}
</script>
