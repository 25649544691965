var render, staticRenderFns
import script from "./WtFormText.vue?vue&type=script&lang=js&"
export * from "./WtFormText.vue?vue&type=script&lang=js&"
import style0 from "./WtFormText.vue?vue&type=style&index=0&id=b8f13ac4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8f13ac4",
  null
  
)

export default component.exports