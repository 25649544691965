<script type="text/jsx">
export default {
  name: 'WtTableSearch',
  isAutoComponent: true,
  props: {
    text: String
  },
  provide: {
    searchData: {}
  },
  mounted() {},
  methods: {
    onClickSearch() {
      this.$emit('on-finish', { test: true })
    }
  },
  computed: {},
  render(h) {
    const content = []
    this.$slots.default.forEach(el => {
      console.log('el', el)
      content.push(el)
    })
    console.log('injections', this.injections)
    return (<div class='wt-table-search'>
      { content }
      <div>
        <button onClick={this.onClickSearch}>查询</button>
      </div>
    </div>)
  }
}
</script>
<style lang="scss" scoped>
  .wt-table-search{display: flex;flex-wrap: wrap;}
</style>
