import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router)

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/
const dynamicRouters = [
  {
    path: '/login',
    component: () => import('@/modules/login/index'),
    meta: { hidden: true, hiddenInTab: true }
  },
  {
    path: '/404',
    component: () => import('@/modules/layout/404'),
    meta: { hidden: true, hiddenInTab: true }
  },
  {
    path: '/500',
    component: () => import('@/modules/layout/500'),
    meta: { hidden: true, hiddenInTab: true }
  },
  {
    path: '/',
    redirect: '/welcome',
    component: () => import('@/components/WtLayout/WtMainLayout'),
    meta: { hidden: true, hiddenInTab: true },
    children: [{
      path: 'welcome',
      name: 'WELCOME',
      component: () => import('@/modules/home/dashboard.vue'),
      meta: { title: '首页', icon: 'el-icon-s-platform', hidden: false, hiddenInTab: true }
    }]
  }
]

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  // mode: 'history', // 后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: dynamicRouters
})

router.afterEach((to, from) => {
  store.dispatch('updateCurrentRoute', to)
  if (to.meta && !to.meta.hiddenInTab) {
    store.dispatch('addView', to)
  }
})

export default router
