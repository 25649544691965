<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <el-form :model="detailForm" ref="form" class="wt-page-form" label-width="120px" size="small">
        <el-form-item class="wt-detail-form-item" label="标题" prop="messageTitle"
          :rules="[ { required: true, message: '请输入标题', trigger: 'blur' } ]">
          <el-input placeholder="请输入标题" v-model="detailForm.messageTitle" :disabled="viewType == 'view'" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="消息简介" prop="messageSummary"
          :rules="[ { required: true, message: '请输入消息简介', trigger: 'blur' } ]">
          <el-input placeholder="请输入消息简介" v-model="detailForm.messageSummary" :disabled="viewType == 'view'" maxlength="30"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="消息内容" prop="messageContent"
          :rules="[ { required: true, message: '请输入消息内容', trigger: 'blur' } ]">
          <!-- <el-input type="textarea" placeholder="请输入消息内容" v-model="detailForm.messageContent" :disabled="viewType == 'view'" maxlength="65,535"></el-input> -->
          <wt-tinymce v-model="detailForm.messageContent" :readonly="viewType == 'view'"></wt-tinymce>
        </el-form-item>
        <!-- <el-form-item class="wt-detail-form-item" label="创建人ID" prop="createUserId"
          >
          <el-input placeholder="请输入" v-model="detailForm.createUserId" :disabled="viewType == 'view'" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="状态" prop="isPublish"
                      :rules="[{ required: true, message: '请选择启用状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
          <el-switch v-model="detailForm.isPublish" :active-value="1" :inactive-value="0" :disabled="viewType == 'view'"></el-switch>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="创建时间" prop="createTimeRange"
          :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-date-picker type="date" placeholder="请输入" v-model="detailForm.createTime" value-format="yyyy-MM-dd" :disabled="viewType == 'view'"></el-date-picker>
        </el-form-item> -->
      </el-form>
    </div>
    <div slot="footer">
      <wt-button type="primary" v-if="detailForm.isPublish === 0" :loading="isFromLoading" @click="onClickPublish">发布</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <!-- <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['RTMESSAGE:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['RTMESSAGE:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button> -->
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'rtMessageDetail',
  isAutoComponent: true,
  props: ['detailData', 'viewType'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {
        isPublish: 0
      }
    }
  },
  mounted() {
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  methods: {
    onClickPublish() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否发布?').then(() => {
        if (that.viewType === 'add') {
          that.requestAdd(1)
        } else if (that.viewType === 'edit') {
          that.requestSave(1)
        }
      }).catch(() => {})
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestSave()
      }).catch(() => {
      })
    },
    onClickClose() {
      this.$emit('on-close')
    },
    // 查看详情
    requestDetail() {
      const that = this
      this.$wtRequest({
        url: '/rtMessage/detail',
        method: 'post',
        data: {
          id: that.detailData.id
        }
      }).then(res => {
        this.detailForm = res.data
      })
    },
    requestSave(isPublish) {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm }
      formData.isPublish = isPublish || 0
      that.$wtRequest({
        url: '/rtMessage/update',
        method: 'post',
        // data: formData
        data: {
          isPublish: formData.isPublish,
          id: formData.id,
          messageTitle: formData.messageTitle,
          messageSummary: formData.messageSummary,
          messageContent: formData.messageContent
        }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestAdd(isPublish) {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm }
      formData.isPublish = isPublish || 0
      that.$wtRequest({
        url: '/rtMessage/add',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
</style>
