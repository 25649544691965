<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <el-form :model="detailForm" ref="form" class="wt-page-form" label-width="120px" size="small">
        <!-- <el-form-item class="wt-detail-form-item" label="轮播图类型 0/默认" prop="bannerType"
                      :rules="[ { required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' } ]">
          <el-select multiple placeholder="请选择轮播图类型 0/默认" v-model="detailForm.bannerType" :disabled="viewType == 'view'">
            <el-option label="请选择" value=""></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item class="wt-detail-form-item" label="标题" prop="bannerTitle"
          :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input placeholder="请输入标题" v-model="detailForm.bannerTitle" :disabled="viewType == 'view'" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="轮播图片" prop="bannerUrl" :rules="[{ required: true, message: '图片不能为空', validator: $wtValidator.ValidatorWtUpload, trigger: 'change' }]">
          <wt-file-upload
            v-model="detailForm.bannerUrl"
            ref="wtOssUpload"
            list-type="image"
            data-type="url"
            :max="1"
            accept=".jpg,.png"
            :file-limit="1024 * 1024 * 5">
            <span slot="tip"><span class="important-text">*注：</span>750x400px，支持png、jpg格式，小于5M</span>
          </wt-file-upload>
        </el-form-item>
        <!-- <el-form-item class="wt-detail-form-item" label="轮播图路径" prop="bannerUrl"
          >
          <el-input type="textarea" placeholder="请输入" v-model="detailForm.bannerUrl" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="轮播图文件格式" prop="bannerFileType"
          >
          <el-input placeholder="请输入" v-model="detailForm.bannerFileType" :disabled="viewType == 'view'" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="创建人ID" prop="createUserId"
          >
          <el-input placeholder="请输入" v-model="detailForm.createUserId" :disabled="viewType == 'view'" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="排序" prop="orderNum"
          :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input placeholder="请输入" v-model="detailForm.orderNum" :disabled="viewType == 'view'" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="状态" prop="isPublish"
                      :rules="[{ required: true, message: '请选择启用状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
          <el-switch v-model="detailForm.isPublish" :active-value="1" :inactive-value="0" :disabled="viewType == 'view'"></el-switch>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="创建时间" prop="createTimeRange"
          :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-date-picker type="date" placeholder="请输入" v-model="detailForm.createTime" value-format="yyyy-MM-dd" :disabled="viewType == 'view'"></el-date-picker>
        </el-form-item> -->
      </el-form>
    </div>
    <div slot="footer">
      <wt-button type="primary" :loading="isFromLoading" @click="onClickPublish">发布</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <!-- <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['RTBANNER:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['RTBANNER:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button> -->
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'rtBannerDetail',
  isAutoComponent: true,
  props: ['detailData', 'viewType'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {
        isPublish: 0
      }
    }
  },
  mounted() {
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  methods: {
    onClickPublish() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否发布?').then(() => {
        if (that.viewType === 'add') {
          that.requestAdd(1)
        } else if (that.viewType === 'edit') {
          that.requestSave(1)
        }
      }).catch(() => {})
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestSave()
      }).catch(() => {
      })
    },
    onClickClose() {
      this.$emit('on-close')
    },
    // 查看详情
    requestDetail() {
      const that = this
      this.$wtRequest({
        url: '/rtBanner/detail',
        method: 'post',
        data: {
          id: that.detailData.id
        }
      }).then(res => {
        that.detailForm = res.data
        that.$refs.wtOssUpload.updateFileList(that.detailForm.bannerUrl)
      })
    },
    requestSave(isPublish) {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm }
      formData.isPublish = isPublish || 0
      that.$wtRequest({
        url: '/rtBanner/update',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestAdd(isPublish) {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm }
      formData.bannerFileType = that.detailForm.bannerUrl.split('.')[that.detailForm.bannerUrl.split('.').length - 1]
      formData.isPublish = isPublish || 0
      that.$wtRequest({
        url: '/rtBanner/add',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
</style>
