import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import Fragment from 'vue-fragment'
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import '@/styles/index.scss'
import '@/styles/temp.scss'
import '@/utils/wtPermission'
import wtConst from '@/utils/wtConst'
import wtUtil from '@/utils/wtUtil'
import wtAuthUtil from '@/utils/wtAuthUtil'
import wtValidator from '@/utils/wtValidator'
import wtRequest from '@/networks/wtRequest'
import components from '@/components'
import moduleComponents from '@/modules'
import '@/filters'
import moment from 'moment'
import 'moment/locale/zh-cn'
Vue.use(Fragment.Plugin)
Vue.prototype.$wtUtil = wtUtil
Vue.prototype.$wtAuthUtil = wtAuthUtil
Vue.prototype.$wtValidator = wtValidator
Vue.prototype.$wtConst = wtConst
Vue.prototype.$wtRequest = wtRequest
Vue.prototype.$moment = moment
Object.keys(components).forEach(key => {
  const component = components[key]
  Vue.component(component.name, component)
})
Object.keys(moduleComponents).forEach(key => {
  const component = moduleComponents[key]
  Vue.component(component.name, component)
})

Vue.use(ElementUI, { size: 'small' })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
