import WtAuth from './WtAuth'
import WtButton from './WtButton'
import WtDataForm from './WtDataForm'
import WtDictSelect from './WtDictSelect'
import WtFileUpload from './WtFileUpload'
import WtFormTable from './WtFormTable'
import WtPageDialog from './WtPageDialog'
import WtPageSection from './WtPageSection'
import WtSearchForm from './WtSearchForm'
import WtSearchFormItem from './WtSearchFormItem'
import WtSection from './WtSection'
import WtTextButton from './WtTextButton'
import WtTinymce from './WtTinymce'

const vueModules = []
const modules = require.context('@/components', true, /.vue$/)
modules.keys().forEach(key => {
  const module = modules(key).default
  if (module && module.isAutoComponent) {
    vueModules.push(module)
  }
})

export default {
  WtAuth,
  WtButton,
  WtDataForm,
  WtDictSelect,
  WtFileUpload,
  WtFormTable,
  WtPageDialog,
  WtPageSection,
  WtSearchForm,
  WtSearchFormItem,
  WtSection,
  WtTextButton,
  WtTinymce,
  ...vueModules
}
