import wtRequest from '@/networks/wtRequest'
import wtUtil from '@/utils/wtUtil'
import { getToken, setToken, setStorgeItem, getStorgeItem, clearStorge } from '@/utils/wtStorge'
import store from '@/store/index'
import staticRoutes from '@/router/staticRoutes'

const importVue = (vueName) => {
  return () => import(`@/modules/${vueName}.vue`)
}

const auth = {
  state: {
    token: getToken(),
    userInfo: getStorgeItem('userInfo'),
    roleId: '',
    orgId: '',
    isDynamicRoutesInit: false,
    roleList: [],
    moduleItem: {},
    moduleList: [],
    orgUserRoleList: [],
    roleCodeList: [],
    moduleCodeList: [],
    permissionCodeList: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    SET_USERINFO: (state, data) => {
      state.userInfo = data
      setStorgeItem('userInfo', data)
    },
    SET_AUTHINFO: (state, data) => {
      state.roleCodeList = data.roleList || []
      state.moduleCodeList = data.moduleList || []
      state.permissionCodeList = data.permissionList || []
      state.orgUserRoleList = data.orgUserRoleList || []
      state.roleId = data.roleId
      state.orgId = data.orgId
    },
    SET_DYNAMIC_ROUTES: (state, dynamicRoutes) => {
      state.isDynamicRoutesInit = true
    },
    SET_ROLE_LIST: (state, roles) => {
      state.roleList = roles
    },
    SET_MODULE_ITEM: (state, moduleItem) => {
      state.moduleItem = moduleItem
    },
    SET_MODULE_LIST: (state, moduleList) => {
      state.moduleList = moduleList
    }
  },
  actions: {
    // 登录
    requestLogin({ commit }, formData) {
      const username = formData.username.trim()
      const password = formData.password
      return new Promise((resolve, reject) => {
        wtRequest({
          url: '/auth/login',
          method: 'post',
          data: {
            'username': username,
            'password': password
          }
        }).then(response => {
          commit('SET_TOKEN', response.data.token)
          commit('SET_USERINFO', response.data.user)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    requestLogout({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLE_LIST', [])
        clearStorge()
        resolve()
      })
    },
    //  切换用户身份
    switchUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', data.token)
        commit('SET_USERINFO', data.user)
        resolve()
      })
    },
    // 获取用户菜单权限
    requestDynamicRoutes({ commit, state }) {
      const isAuthEnable = store.state.app.theme.isAuthEnable
      const makeMenuArray = (item) => {
        if (isAuthEnable && state.moduleCodeList.indexOf(item.menuName) === -1) {
          return null
        }
        const menu = {
          path: item.menuPath,
          name: item.menuName,
          meta: {
            hidden: item.isHidden === 1,
            hiddenInTab: item.isHiddenInTab === 1,
            title: item.menuTitle,
            icon: item.menuIcon
          }
        }
        if (wtUtil.isNotBlank(item.menuRedirect)) {
          menu.redirect = item.menuRedirect
        }
        if (item.menuComponent === 'WtMainLayout') {
          menu.component = () => import('@/components/WtLayout/WtMainLayout')
        } else if (wtUtil.isBlank(item.menuComponent)) {
          menu.component = () => import('@/components/WtLayout/WtBlankLayout')
        } else {
          menu.component = importVue(item.menuComponent)
        }
        if (item.children && item.children.length > 0) {
          const children = []
          item.children.forEach(childItem => {
            const childMenu = makeMenuArray(childItem)
            if (childMenu) {
              children.push(childMenu)
            }
          })
          menu.children = children
        }
        return menu
      }
      return new Promise((resolve, reject) => {
        wtRequest({
          url: '/auth/authInfo',
          method: 'post',
          data: { }
        }).then(resp => {
          commit('SET_AUTHINFO', resp.data)
          let allMenuList = []
          //  动态路由
          const menuContent = resp.data.menuContent || '[]'
          const menuContentArray = JSON.parse(menuContent)
          menuContentArray.forEach(item => {
            const menu = makeMenuArray(item)
            if (menu) {
              allMenuList.push(menu)
            }
          })
          allMenuList.push({
            path: '*',
            redirect: '/404',
            meta: { title: '', icon: '', hidden: true }
          })
          //  静态路由，使用@/router/staticRoutes.js
          if (store.state.app.theme.isStaticRouter) {
            allMenuList = [...staticRoutes]
          }
          const resultMenuList = [...allMenuList]
          commit('SET_MODULE_LIST', resultMenuList)
          commit('SET_DYNAMIC_ROUTES', resultMenuList)
          resolve(resultMenuList)
        }).catch(error => {
          commit('SET_DYNAMIC_ROUTES', [])
          reject(error)
        })
      })
    },
    // 设置的默认模块
    updateModuleItem: ({ commit }, data) => {
      commit('SET_MODULE_ITEM', data)
    },
    //  获取角色列表
    getRoleList({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        wtRequest({
          url: '/auth/roleListAll',
          method: 'post',
          data: { 'search': data }
        }).then(resp => {
          commit('SET_ROLE_LIST', resp.data.list)
          const resultList = resp.data.list.map((item) => {
            return { value: item.id, label: item.roleName }
          })
          resolve(resultList)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default auth
