export const MENUCOMPONENTTYPE_ENUM = {
  ROOT: {
    KEY: 'ROOT',
    NAME: '根节点',
    DEFAULT_MENUCOMPONENT: 'WtMainLayout'
  },
  GROUP: {
    KEY: 'GROUP',
    NAME: '父节点',
    DEFAULT_MENUCOMPONENT: ''
  },
  PAGE: {
    KEY: 'PAGE',
    NAME: '页面',
    DEFAULT_MENUCOMPONENT: ''
  }
}
