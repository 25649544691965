<template>
  <div>
    <el-button type="primary" @click="onClickPicker">请选择</el-button>
    <div class="wt-org-role-container">
      <div class="wt-org-role-item" v-for="(item, itemIndex) in value" :key="itemIndex">
        <el-tag size="medium" @close="onClickTag(item, itemIndex)" closable>{{item.orgName}} {{item.roleName}}</el-tag>
      </div>
    </div>
    <wt-dialog ref="wtPreviewDialog" title="选择机构和角色">
      <div class="wt-org-role-wrapper">
        <el-form :model="detailForm" ref="form" class="wt-page-form" label-position="top">
          <el-form-item class="wt-detail-form-item" label="机构" prop="orgId"
                        :rules="[ { required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]"
                        required>
            <el-cascader ref="orgCascader" v-model="detailForm.orgId" :props="orgProp" placeholder="请选择机构信息"></el-cascader>
          </el-form-item>
          <el-form-item class="wt-detail-form-item" label="角色" prop="roleId"
                        :rules="[ { required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]" required>
            <el-select v-model="detailForm.roleId">
              <el-option v-for="roleItem in roleList" :key="roleItem.id" :value="roleItem.id" :label="roleItem.roleName"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="wt-toolbar wt-margin-t wt-flex-end">
        <wt-button type="default" @click="$refs.wtPreviewDialog.close()">关闭</wt-button>
        <wt-button type="primary" @click="onClickConfirm">确定</wt-button>
      </div>
    </wt-dialog>
  </div>
</template>

<script>
export default {
  name: 'OrgRolePicker',
  props: ['value'],
  inject: {
    elForm: {
      default: ''
    },
    elFormItem: {
      default: ''
    }
  },
  data() {
    const that = this
    return {
      orgProp: {
        value: 'id',
        label: 'name',
        checkStrictly: true,
        lazy: true,
        async lazyLoad(node, resolve) {
          const { level } = node
          if (level === 0) {
            const resultList = await that.requestUserOrgSelectList(0)
            resolve(resultList)
          } else {
            const resultList = await that.requestUserOrgSelectList(node.data.id)
            resolve(resultList)
          }
        }
      },
      roleList: [],
      detailForm: {}
    }
  },
  async mounted() {
    this.detailForm = {}
    this.roleList = await this.requestUserRoleSelectList()
  },
  methods: {
    onClickTag(item, itemIndex) {
      const values = [...this.value]
      values.splice(itemIndex, 1)
      this.$emit('input', values)
      if (this.elFormItem) {
        this.elFormItem.validate('blur')
      }
    },
    onClickPicker() {
      this.$refs.wtPreviewDialog.open()
    },
    onClickConfirm() {
      const that = this
      this.$wtUtil.validateDetailForms(this).then((valid) => {
        if (valid) {
          const orgNode = that.$refs.orgCascader.getCheckedNodes()[0].data
          let roleName = ''
          const orgName = orgNode.name
          that.roleList.forEach((item) => {
            if (item.id === that.detailForm.roleId) {
              roleName = item.roleName
            }
          })
          const orgId = orgNode.id
          const roleId = that.detailForm.roleId
          const dataKey = `${orgId}-${roleId}`
          const dataList = [...that.value].filter(item => {
            const itemKey = `${item.orgId}-${item.roleId}`
            return dataKey !== itemKey
          })
          dataList.push({
            orgId: orgNode.id,
            orgName: orgName,
            roleId: that.detailForm.roleId,
            roleName: roleName
          })
          that.$emit('input', dataList)
          if (that.elFormItem) {
            that.elFormItem.validate('blur')
          }
          that.$refs.wtPreviewDialog.close()
        }
      }).catch(() => {
      })
    },
    requestUserRoleSelectList() {
      return new Promise((resolve, reject) => {
        this.$wtRequest({
          url: '/auth/userRoleSelectList',
          method: 'post',
          data: {}
        }).then(resp => {
          const resultList = resp.data && resp.data.list ? resp.data.list : []
          resolve(resultList)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    requestUserOrgSelectList(parentId) {
      return new Promise((resolve, reject) => {
        this.$wtRequest({
          url: '/auth/userOrgSelectList',
          method: 'post',
          data: { id: parentId }
        }).then(resp => {
          const resultList = resp.data && resp.data.list ? resp.data.list : []
          resultList.filter(item => {
            item.leaf = !item.childrenNum || item.childrenNum <= 0
          })
          resolve(resultList)
        }).catch((e) => {
          reject(e)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wt-org-role-container {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0;
}

.wt-org-role-item {
  margin: 0 4px 0 0;
}
.wt-org-role-wrapper{
  max-width: 400px;
}
</style>
