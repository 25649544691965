<template>
  <div class="wt-data-form-toolbar">
    <div class="left">
      <slot></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WtDataFormToolbar',
  isAutoComponent: true
}
</script>

<style lang="scss" scoped>

</style>
