<template>
  <wt-page v-loading="isFromLoading">
    <div class="wt-page-container">
      <el-form :model="detailForm" ref="form" class="wt-page-form" label-width="120px" size="small">
        <el-form-item class="wt-detail-form-item" label="菜单方案名称" prop="menuGroupName"
          :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input placeholder="请输入" v-model="detailForm.menuGroupName" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="菜单方案说明" prop="menuGroupSummary"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input type="textarea" placeholder="请输入" v-model="detailForm.menuGroupSummary" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="所属角色" prop="roleIdList">
          <el-select multiple placeholder="请选择角色" v-model="detailForm.roleIdList" :disabled="viewType == 'view'">
            <el-option v-for="item in roleList" :label="item.roleName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="状态" prop="isPublish"
                      :rules="[{ required: true, message: '请选择启用状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
          <el-switch v-model="detailForm.isPublish" :active-value="1" :inactive-value="0" :disabled="viewType == 'view'"></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="wt-page-container wt-page-margin-top">
      <div class="wt-toolbar" v-if="viewType != 'view'">
        <el-button type="primary" @click="onClickMenuAdd">新建一级菜单</el-button>
        <el-button type="primary" @click="onClickMenuPreview">源码视图</el-button>
      </div>
      <wt-form-table :form="form">
        <el-table-column prop="menuTitle" label="菜单标题" min-width="100">
          <template slot-scope="scope"><i :class="[scope.row.menuIcon]"></i><span style="margin-left: 4px;">{{scope.row.menuTitle}}</span></template>
        </el-table-column>
        <el-table-column prop="menuPath" label="菜单路径"></el-table-column>
        <el-table-column prop="menuComponent" label="菜单组件" min-width="150"></el-table-column>
        <el-table-column prop="menuName" label="菜单标示"></el-table-column>
        <el-table-column prop="orderNum" label="排序"></el-table-column>
        <el-table-column prop="isHidden" label="是否在菜单隐藏">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.MENU_ISHIDDEN_ENUM_CONTENT[scope.row.isHidden]"></wt-enum-content></template>
        </el-table-column>
        <el-table-column prop="isHiddenInTab" label="是否在标签栏隐藏">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.MENU_ISHIDDEN_ENUM_CONTENT[scope.row.isHiddenInTab]"></wt-enum-content></template>
        </el-table-column>
          <el-table-column prop="menuRedirect" label="菜单跳转路径"></el-table-column>
        <el-table-column label="操作" fixed="right" width="250" >
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="onClickMenuSubAdd(scope.row)">新建子菜单</el-button>
            <el-button size="mini" type="primary" @click="onClickMenuEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="onClickMenuDelete(scope.row)" class="danger">删除</el-button>
          </template>
        </el-table-column>
      </wt-form-table>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['SYSMENUGROUP:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['SYSMENUGROUP:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
    <wt-drawer ref="wtDialog" :size="600">
      <menu-detail :detailData="menuDetailData" :parentData="menuParentData" @on-save="onMenuDetailSave" @on-close="$refs.wtDialog.close()"></menu-detail>
    </wt-drawer>
    <wt-dialog ref="wtPreviewDialog">
      <div>
        <el-input type="textarea" v-model="previewContent" :rows="30"></el-input>
      </div>
      <div class="wt-toolbar wt-margin-t wt-flex-end">
        <wt-button type="default" @click="$refs.wtPreviewDialog.close()">关闭</wt-button>
        <wt-button type="primary" @click="onClickPreviewSave">保存</wt-button>
      </div>
    </wt-dialog>
  </wt-page>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import MenuDetail from './menuDetail'
export default {
  name: 'sysMenuGroupDetail',
  props: ['detailData', 'viewType'],
  components: {
    MenuDetail
  },
  data() {
    return {
      isMenuAddVisiable: false,
      form: {
        table: {
          emptyText: '暂无数据',
          'default-expand-all': true,
          'tree-props': { children: 'children', hasChildren: 'hasChildren' },
          'row-key': 'id'
        },
        formResult: {
          list: []
        }
      },
      isFromLoading: false,
      detailForm: {
        isPublish: 1
      },
      previewContent: '[]',
      menuMap: {},
      menuParentMap: {},
      menuDetailData: {},
      menuParentData: {},
      roleList: []
    }
  },
  mounted() {
    this.menuMap = {}
    this.menuParentMap = {}
    this.previewContent = '[]'
    this.form.formResult.list = []
    this.requestRoleList()
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  methods: {
    buildTableList() {
      const parentItemListMap = { '': [] }
      const sortFunc = (obj1, obj2) => {
        return obj2.orderNum - obj1.orderNum
      }
      const fillChildrenFunc = (item) => {
        if (parentItemListMap[item.id]) {
          item.children = parentItemListMap[item.id]
          item.children.forEach((childItem) => {
            fillChildrenFunc(childItem)
          })
        }
      }
      Object.values(this.menuMap).forEach(item => {
        if (!parentItemListMap[item.parentId]) {
          parentItemListMap[item.parentId] = []
        }
        item.children = []
        parentItemListMap[item.parentId].push(item)
      })
      Object.keys(parentItemListMap).forEach(itemKey => {
        parentItemListMap[itemKey] = parentItemListMap[itemKey].sort(sortFunc)
      })
      const topItemList = parentItemListMap['']
      topItemList.forEach(item => {
        fillChildrenFunc(item)
      })
      return topItemList
    },
    parseMenuGroupContent(menuGroupContent) {
      const that = this
      that.menuMap = {}
      that.menuParentMap = {}
      const menuList = JSON.parse(menuGroupContent || '[]')
      const fillChildrenFunc = (item) => {
        that.menuMap[item.id] = item
        that.menuParentMap[item.id] = item.parentId
        if (item.children) {
          item.children.forEach(childItem => {
            fillChildrenFunc(childItem)
          })
        }
      }
      menuList.forEach(item => {
        fillChildrenFunc(item)
      })
      return this.buildTableList()
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestSave()
      }).catch(() => {
      })
    },
    onClickClose() {
      this.$emit('on-close')
    },
    onClickMenuAdd() {
      this.menuDetailData = {
        id: this.$wtUtil.uuid(),
        parentId: '',
        menuComponent: 'WtMainLayout',
        menuIcon: 'el-icon-warning-outline',
        menuName: '',
        isHidden: 0,
        isHiddenInTab: 0,
        orderNum: 100
      }
      this.menuParentData = { menuTitle: '根节点' }
      this.$refs.wtDialog.open()
    },
    onClickMenuPreview() {
      this.previewContent = JSON.stringify(this.form.formResult.list, null, 2)
      this.$refs.wtPreviewDialog.open()
    },
    onClickPreviewSave() {
      const result = this.parseMenuGroupContent(this.previewContent)
      this.form.formResult.list = result
      this.$refs.wtPreviewDialog.close()
    },
    onClickMenuSubAdd(rowData) {
      this.menuDetailData = {
        id: this.$wtUtil.uuid(),
        parentId: rowData.id,
        menuComponent: '',
        menuIcon: 'el-icon-warning-outline',
        menuName: '',
        isHidden: 0,
        isHiddenInTab: 0,
        orderNum: 100
      }
      this.menuParentData = rowData
      this.$refs.wtDialog.open()
    },
    onClickMenuEdit(rowData) {
      this.menuDetailData = Object.assign({}, rowData)
      this.menuMap[this.menuDetailData.id] = this.menuDetailData
      if (this.$wtUtil.isNotBlank(rowData.parentId)) {
        this.menuParentData = this.menuMap[rowData.parentId]
      } else {
        this.menuParentData = { menuTitle: '根节点' }
      }
      this.$refs.wtDialog.open()
    },
    onClickMenuDelete(rowData) {
      Vue.delete(this.menuMap, rowData.id)
      Vue.delete(this.menuParentMap, rowData.id)
      this.form.formResult.list = this.buildTableList()
    },
    onMenuDetailSave(data) {
      this.menuMap[data.id] = data
      this.menuParentMap[data.id] = data.parentId
      this.form.formResult.list = this.buildTableList()
      this.$refs.wtDialog.close()
    },
    // 获取角色
    requestRoleList() {
      this.$wtRequest({
        url: '/sysMenuGroup/roleList',
        method: 'post',
        data: { search: '' }
      }).then(res => {
        this.roleList = res.data.list
      })
    },
    // 查看详情
    requestDetail() {
      const that = this
      that.isFromLoading = true
      this.$wtRequest({
        url: '/sysMenuGroup/detail',
        method: 'post',
        data: {
          id: that.detailData.id
        }
      }).then(res => {
        that.isFromLoading = false
        that.detailForm = res.data
        that.form.formResult.list = this.parseMenuGroupContent(res.data.menuGroupContent)
      }).catch(() => {
        that.isFromLoading = true
      })
    },
    requestSave() {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm, menuGroupContent: JSON.stringify(this.form.formResult.list || []) }
      that.$wtRequest({
        url: '/sysMenuGroup/update',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm, menuGroupContent: JSON.stringify(this.form.formResult.list || []) }
      that.$wtRequest({
        url: '/sysMenuGroup/add',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
</style>
