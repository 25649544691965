<template>
  <el-dialog :title="title" :destroy-on-close="true" append-to-body :visible.sync="isVisible" :width="width" custom-class="wt-dialog">
    <slot/>
  </el-dialog>
</template>

<script>
export default {
  name: 'wt-dialog',
  isAutoComponent: true,
  props: {
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '700px'
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    open() {
      this.isVisible = true
    },
    close() {
      this.isVisible = false
    }
  }
}
</script>

<style lang="scss">
.wt-dialog{
  .el-dialog__body {
    padding: 20px;
  }
}
</style>
