<template>
  <div class="wt-data-form-pagination">
    <el-pagination
      :current-page="parseInt(form.formResult.currentPage)"
      :page-sizes="[1, 10, 20, 50, 100]"
      :page-size="parseInt(form.formResult.pageSize)"
      :layout="layout ? layout : 'prev, pager, next, sizes, jumper'"
      :total="parseInt(form.formResult.total)"
      @current-change="onPageCurrentChange"
      @size-change="onPageSizeChange">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'WtDataFormPagination',
  props: ['form', 'layout'],
  methods: {
    onPageCurrentChange(pageNum) {
      //  切换页码
      this.form.pageData.currentPage = pageNum
      this.$emit('request-form-list')
    },
    onPageSizeChange(pageSize) {
      //  切换页数
      this.form.pageData.pageSize = pageSize
      this.$emit('request-form-list')
    }
  }
}
</script>

<style scoped>

</style>
