<template>
  <div class="wt-admin-wrapper">
    <wt-default-layout v-if="theme.name == THEME_ENUM.DEFAULT.key" />
    <wt-column-layout v-else-if="theme.name == THEME_ENUM.COLUMN.key" />
    <wt-theme-setting></wt-theme-setting>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { THEME_ENUM } from './constants/helper'
import WtThemeSetting from './setting/WtThemeSetting'
import WtDefaultLayout from './layouts/default/WtDefaultLayout'
import WtColumnLayout from './layouts/column/WtColumnLayout'

export default {
  name: 'mainLayout',
  data() {
    return {
      THEME_ENUM: THEME_ENUM
    }
  },
  components: {
    WtThemeSetting,
    WtDefaultLayout,
    WtColumnLayout
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...mapState({
      theme: state => state.app.theme
    })
  }
}
</script>
<style lang="scss" scoped>
  .wt-admin-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
</style>
