<script type="text/jsx">
export default {
  name: 'WtDataFormSearchForm',
  isAutoComponent: true,
  props: {
    maxCol: {
      default: 24,
      type: Number
    },
    col: {
      default: 6,
      type: Number
    }
  },
  data() {
    return {
      isExpand: false
    }
  },
  methods: {
    onClickMore() {
      this.isExpand = !this.isExpand
    },
    _renderButtonsMore({ maxColNum, colNum }) {
      const isMoreVisible = (colNum + 1 > maxColNum)
      if (!isMoreVisible) {
        return null
      }
      return (
        <el-link class='wt-search-form-button-more' type='primary' underline={false} onClick={this.onClickMore}>
          {
            this.isExpand ? <div>收起<i class='wt-arrow-icon el-icon-arrow-up' ></i></div> : <div>展开<i class='wt-arrow-icon el-icon-arrow-down'></i></div>
          }
        </el-link>
      )
    },
    _renderButtons({ maxColNum, colNum }) {
      let offset = (maxColNum - colNum - 1) * this.col
      if (colNum < maxColNum) {
        //  算上按钮区域不足一行
        offset = (maxColNum - colNum - 1) * this.col
      } else if (!this.isExpand && colNum % maxColNum === 0) {
        //  收起状态
        offset = 0
      } else if (this.isExpand) {
        //  展开状态
        offset = (maxColNum - colNum % maxColNum - 1) * this.col
      } else {
        offset = 0
      }
      const offsetClassName = `wt-col-offset-${offset}`
      return (<div class={['wt-col', 'wt-col-6', offsetClassName, 'wt-search-form-buttons']}>
        {this.$slots.buttons}
        {this._renderButtonsMore({ maxColNum, colNum })}
      </div>)
    },
    _renderContent() {
      if (!this.$slots.search || this.$slots.search.length <= 0) {
        return null
      }
      const searchItemList = this.$slots.search.filter(item => item.tag)
      const maxColNum = this.maxCol / this.col
      const colNum = searchItemList.length
      const hiddenColIndex = this.maxCol / this.col - 1
      return (<div class='wt-search-form-wrapper wt-row'>
        {
          searchItemList.map((item, itemIndex) => {
            if (!this.isExpand && itemIndex >= hiddenColIndex) {
              return null
            }
            return item
          })
        }
        {this._renderButtons({ maxColNum, colNum })}
      </div>)
    }
  },
  render(h) {
    return (<div class='wt-search-form'>
      {this._renderContent()}
    </div>)
  }
}
</script>

<style lang="scss" scoped>
.wt-search-form-buttons{
  text-align: right;
}
.wt-search-form-button-more{
  margin: 0 10px;
  font-size: 12px;
  .wt-arrow-icon {
    margin-left: 6px;
  }
}
</style>
