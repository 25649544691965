<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <el-form :model="detailForm" ref="form" class="wt-page-form" label-width="120px" size="small">
        <el-form-item class="wt-detail-form-item" label="应用名称" prop="appName"
          :rules="[ { required: true, message: '请输入应用名称', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input placeholder="请输入应用名称" v-model="detailForm.appName" :disabled="viewType == 'view'" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="应用图标" prop="fileUrl" :rules="[{ required: true, message: '图片不能为空', validator: $wtValidator.ValidatorWtUpload, trigger: 'change' }]">
          <wt-file-upload
            v-model="detailForm.fileUrl"
            ref="wtOssUpload"
            list-type="image"
            data-type="url"
            :max="1"
            accept=".jpg,.png"
            :file-limit="1024 * 1024 * 5"
            :readonly="viewType == 'view'">
            <span slot="tip"><span class="important-text">*注：</span>仅支持png、jpg格式，小于5M</span>
          </wt-file-upload>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="应用分类" prop="appType"
          :rules="[ { required: true, message: '请选择应用分类', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' } ]">
          <el-select placeholder="请选择分类" v-model="detailForm.appType" :disabled="viewType == 'view'">
            <!-- <el-option label="默认" :value="0"></el-option> -->
            <el-option label="运营" :value="1"></el-option>
            <el-option label="管理" :value="2"></el-option>
            <el-option label="服务" :value="3"></el-option>
            <el-option label="办公" :value="4"></el-option>
            <el-option label="集控平台" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="跳转路径" prop="appSkipUrl"
          :rules="[ { required: true, message: '请输入跳转路径', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input type="textarea" placeholder="请输入" v-model="detailForm.appSkipUrl" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="是否启用" prop="isPublish"
          :rules="[ { required: true, message: '请选择应是否启用', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-radio-group v-model="detailForm.isPublish" :disabled="viewType == 'view'">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item class="wt-detail-form-item" label="状态" prop="isPublish"
                      :rules="[{ required: true, message: '请选择启用状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
          <el-switch v-model="detailForm.isPublish" :active-value="1" :inactive-value="0" :disabled="viewType == 'view'"></el-switch>
        </el-form-item> -->
        <!-- <el-form-item class="wt-detail-form-item" label="应用图标路径" prop="fileUrl"
          >
          <el-input type="textarea" placeholder="请输入" v-model="detailForm.fileUrl" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="应用图标文件格式" prop="fileType"
          >
          <el-input placeholder="请输入" v-model="detailForm.fileType" :disabled="viewType == 'view'" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="创建人ID" prop="createUserId"
          >
          <el-input placeholder="请输入" v-model="detailForm.createUserId" :disabled="viewType == 'view'" maxlength="20"></el-input>
        </el-form-item> -->
        <!-- <el-form-item class="wt-detail-form-item" label="创建时间" prop="createTimeRange"
          :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-date-picker type="date" placeholder="请输入" v-model="detailForm.createTime" value-format="yyyy-MM-dd" :disabled="viewType == 'view'"></el-date-picker>
        </el-form-item> -->
      </el-form>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
      <!-- <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['RTAPPFILE:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['RTAPPFILE:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button> -->
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'rtAppFileDetail',
  isAutoComponent: true,
  props: ['detailData', 'viewType'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {
        isPublish: 1
      }
    }
  },
  mounted() {
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  methods: {
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestSave()
      }).catch(() => {
      })
    },
    onClickClose() {
      this.$emit('on-close')
    },
    // 查看详情
    requestDetail() {
      const that = this
      this.$wtRequest({
        url: '/rtAppFile/detail',
        method: 'post',
        data: {
          id: that.detailData.id
        }
      }).then(res => {
        that.detailForm = res.data
        that.$refs.wtOssUpload.updateFileList(that.detailForm.fileUrl)
      })
    },
    requestSave() {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm }
      that.$wtRequest({
        url: '/rtAppFile/update',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm }
      formData.fileType = that.detailForm.fileUrl.split('.')[that.detailForm.fileUrl.split('.').length - 1]
      that.$wtRequest({
        url: '/rtAppFile/add',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
</style>
