<template>
  <wt-page-dialog-wrapper title="" custom-class="wt-page-detail-dialog" append-to-element="wt-app-main" z-index="500" :modal="false" :destroy-on-close="true" :visible.sync="isVisible">
    <slot v-if="isVisible"></slot>
  </wt-page-dialog-wrapper>
</template>

<script>
import WtPageDialogWrapper from './WtPageDialogWrapper'
export default {
  name: 'wt-page-dialog',
  components: {
    WtPageDialogWrapper
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '70%'
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    open() {
      this.isVisible = true
    },
    close() {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
</style>
