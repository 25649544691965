// 颜色配置表，测试数据，正常是从服务端过来的
const THEME_CONFIG = {
  light: {
    '--theme-normal-color': '#3370FF;',
    '--theme-hover-color': '#4E83FD;',
    '--theme-click-color': '#245BDB;',
    '--theme-disable-color': '#BACEFD;',
    '--theme-special-color': '#E1EAFF;',
    '--theme-light-bg-color': '#F0F4FF;',
    '--theme-text-title-color': '#1F2329;',
    '--theme-text-title1-color': '#646A73;',
    '--theme-text-secondary-color': '#8F959E;',
    '--theme-text-grey-color': '#BBBFC4;',
    '--theme-text-white-color': '#FFFFFF;',
    '--theme-fill-level1-color': '#2B2F36;',
    '--theme-fill-level2-color': '#646A73;',
    '--theme-fill-grey-color': '#BBBFC4;',
    '--theme-fill-border-color': '#DEE0E3;',
    '--theme-fill-hover-color': '#EFF0F1;',
    '--theme-fill-bg-color': '#F2F3F5;',
    //  要素
    '--theme-silder-bg-color': '#FFFFFF;',
    '--theme-slider-item-bg-color': '#1F232914;',
    '--theme-slider-item-hover-bg-color': '#1F232914;',
    '--theme-slider-item-text-color': '#1F2329;',
    '--theme-slider-item-text-hover-color': '#3370FF;',
    '--theme-slider-item-radius': '0px;',
    '--theme-slider-border-color': '#DEE0E3;'
  },
  dark: {
    '--theme-normal-color': '#3370FF;',
    '--theme-hover-color': '#4E83FD;',
    '--theme-click-color': '#245BDB;',
    '--theme-disable-color': '#BACEFD;',
    '--theme-special-color': '#E1EAFF;',
    '--theme-light-bg-color': '#F0F4FF;',
    '--theme-text-title-color': '#1F2329;',
    '--theme-text-title1-color': '#646A73;',
    '--theme-text-secondary-color': '#8F959E;',
    '--theme-text-grey-color': '#BBBFC4;',
    '--theme-text-white-color': '#FFFFFF;',
    '--theme-fill-level1-color': '#2B2F36;',
    '--theme-fill-level2-color': '#646A73;',
    '--theme-fill-grey-color': '#BBBFC4;',
    '--theme-fill-border-color': '#DEE0E3;',
    '--theme-fill-hover-color': '#EFF0F1;',
    '--theme-fill-bg-color': '#F2F3F5;',
    //  要素
    '--theme-silder-bg-color': '#313E58;',
    '--theme-slider-item-bg-color': '#1F293E;',
    '--theme-slider-item-hover-bg-color': '#1F293E;',
    '--theme-slider-item-text-color': '#BBBFC4;',
    '--theme-slider-item-text-hover-color': '#FFFFFF;',
    '--theme-slider-item-radius': '0px;',
    '--theme-slider-border-color': '#646A73;'
  }
}

// 存储当前用的是什么色系
let currentConfig = THEME_CONFIG['dark']
// 备份styles
let stylesBackups = null

/**
 * 生成主题代码段
 * @param {Object} config
 * @returns
 */
function createThemeStyle(config) {
  let themeResult = ''
  Object.keys(config).forEach(configKey => {
    themeResult += `${configKey}: ${config[configKey]}`
  })
  const result = `
  :root {
    ${themeResult}
  }`
  return result
}

/**
 * 插入主题style段
 * @param {String} styleString
 */
function insertThemeStyle(styleString) {
  let styleTag = document.querySelector('#themeStyleTag')
  if (!styleTag) {
    styleTag = document.createElement('style')
    styleTag.id = 'themeStyleTag'
    styleTag.setAttribute('type', 'text/css')
    document
      .getElementsByTagName('head')[0]
      .insertBefore(styleTag, document.getElementsByTagName('style')[0])
  }
  styleTag.innerHTML = styleString
}

/**
 * 判断css变量是否被支持
 */
function cssVarIsSupport() {
  var testVar = getComputedStyle(document.body).getPropertyValue('--wt-var')
  return !!testVar
}

/**
 * 根据主题配置生成正则
 * @returns string
 */
function createStyleRegex() {
  let regexStr = ''
  const varList = Object.keys(currentConfig)
  for (let i = 0, len = varList.length; i < len; i++) {
    regexStr += `var\\(${varList[i]}\\)${i < len - 1 ? '|' : ''}`
  }
  return regexStr
}

/**
 * css变量兼容处理-第一次
 */
function compatibleProcessingCssVar() {
  const styles = document
    .getElementsByTagName('head')[0]
    .getElementsByTagName('style')
  stylesBackups = {}
  // 生成正则匹配规则字符串
  const regexStr = createStyleRegex()
  // 遍历所有style标签替换
  for (let i = 1, len = styles.length; i < len; i++) {
    const item = styles[i]
    const replaceReg = new RegExp(regexStr, 'g')
    let styleStr = item.innerHTML
    if (!replaceReg.test(styleStr)) {
      continue
    }
    if (!stylesBackups['theme' + i]) {
      item.setAttribute('id', 'theme' + i)
      stylesBackups['theme' + i] = styleStr
    }
    styleStr = styleStr.replace(replaceReg, function(mactchStr) {
      return currentConfig[mactchStr.split(/\(|\)/)[1]]
    })
    item.innerHTML = styleStr
  }
}

/**
 * 解决兼容浏览器的动态换肤问题
 * css变量兼容处理-后面不需再去获取所有style，通过从内存中拿缓存的style来做字符串替换
 */
function compatibleProcessingCssVarFromCache() {
  // 生成正则匹配规则字符串
  const regexStr = createStyleRegex()
  const styleKeys = Object.keys(stylesBackups)
  // 遍历所有style标签替换
  for (let i = 1, len = styleKeys.length; i < len; i++) {
    const key = styleKeys[i]
    let styleStr = stylesBackups[key]
    const replaceReg = new RegExp(regexStr, 'g')
    styleStr = styleStr.replace(replaceReg, function(mactchStr) {
      return currentConfig[mactchStr.split(/\(|\)/)[1]]
    })
    document.getElementById(key).innerHTML = styleStr
  }
}

/**
 * 适配主题
 * @param {string} theme
 */
function adaptationTheme(theme) {
  // 获取颜色列表
  currentConfig = THEME_CONFIG[theme]
  // 对于兼容css var的浏览器
  if (cssVarIsSupport()) {
    // 生成style
    const styleString = createThemeStyle(currentConfig)
    // 插入style中
    insertThemeStyle(styleString)
  } else {
    // 对于不兼容css var的浏览器（chrome49、ie15以下）
    if (stylesBackups) {
      compatibleProcessingCssVarFromCache()
      return
    }
    compatibleProcessingCssVar()
  }
}

export default {
  adaptationTheme
}
