<template>
  <div class="full-page-container">
    <wt-breadcrumb/>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'WtContainer',
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo,
      permissionCodes: state => state.auth.permissionCodes
    })
  },
  created() {
  },
  methods: {
  },
  mounted() {
  }
}
</script>
