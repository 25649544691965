<script type="text/jsx">
export default {
  name: 'WtTableForm',
  isAutoComponent: true,
  props: {
    text: String
  },
  mounted() {},
  methods: {
    renderSearch() {
      return (
        <div>Search</div>
      )
    }
  },
  computed: {},
  render(h) {
    return (<div class='wt-table-form'>
      { this.$slots.search }
      { this.$slots.toolbar }
      <div>table</div>
      <div>page</div>
    </div>)
  }
}
</script>
<style lang="scss" scoped>
  .wt-table-form{background-color: #FFFFFF;}
</style>
