<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>
<style>
  body {
    --wt-var: 'wt';
  }
</style>
<style scoped>
  html body #app {
    height: 100vh;
    overflow: auto;
    background-color: #f6f8f9;
  }

  html body #app::-webkit-scrollbar-thumb {
    background-color: #eaeaeb;
    border: 3px solid transparent;
    border-radius: 7px;
  }
</style>
