<template>
  <div class="wt-page">
    <slot></slot>
    <div class="wt-page-footer" v-if="isFooterVisiable">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-page',
  isAutoComponent: true,
  props: {
    title: { type: String, default: '' },
    isHeaderVisiable: { type: Boolean, default: true },
    isFooterVisiable: { type: Boolean, default: true }
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>
