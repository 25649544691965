<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  :request-list-url="requestListUrl" :request-delete-url="requestDeleteUrl" :request-detail-url="requestDetailUrl">
      <template slot="search">
        <wt-search-form-item title="版本code" v-model="form.formData.versionCode" type="input"/>
        <wt-search-form-item title="发布状态" v-model="form.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH_STATE_OPTIONS"/>
        <!-- <wt-search-form-item title="创建时间" width="col-3" v-model="form.formData.createTimeRange" type="datetimerange" /> -->
      </template>
      <template slot="toolbar">
        <!-- <wt-button type="primary" v-has-any-premission="['RTVERSIONFILE:ADD']" @click="onClickAdd">新增</wt-button> -->
        <wt-button type="primary" @click="onClickAdd">新增版本</wt-button>
        <wt-button type="primary" :loading="form.isFromLoading" @click="onChangeList">切换到{{updateTitle}}</wt-button>
      </template>
      <template slot="table" v-if="modeType === 1">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column v-if="modeType === 1" prop="version" label="版本号"></el-table-column>
        <el-table-column prop="versionCode" label="版本code"></el-table-column>
        <el-table-column prop="modeType" label="更新方式">
          <template slot-scope="scope">
            <span v-if="scope.row.modeType === 0">默认</span>
            <span v-else-if="scope.row.modeType === 1">整体更新</span>
            <span v-else-if="scope.row.modeType === 2">热更新</span>
          </template>
        </el-table-column>
        <el-table-column v-if="modeType === 2" prop="title" label="标题"></el-table-column>
        <el-table-column v-if="modeType === 1" prop="cue" label="升级提示语" show-overflow-tooltip></el-table-column>
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.ISPUBLISH_STATE_CONTENT[scope.row.isPublish]"></wt-enum-content></template>
        </el-table-column>
        <el-table-column prop="createTime" label="发布时间"></el-table-column>
        <el-table-column prop="username" label="创建人"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <!-- <wt-text-button v-has-any-premission="['RTVERSIONFILE:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['RTVERSIONFILE:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.isDefault == 0" v-has-any-premission="['RTVERSIONFILE:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button> -->
            <wt-text-button v-if="scope.row.isPublish === 0" @click="onClickPublish(scope.row)">发布</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 1" @click="onClickPublish(scope.row)">取消发布</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 0" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 1" @click="onClickView(scope.row)">详情</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 0" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>

      <template slot="table" v-if="modeType === 2">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column v-if="modeType === 1" prop="version" label="版本号"></el-table-column>
        <el-table-column prop="versionCode" label="版本code"></el-table-column>
        <el-table-column prop="modeType" label="更新方式">
          <template slot-scope="scope">
            <span v-if="scope.row.modeType === 0">默认</span>
            <span v-else-if="scope.row.modeType === 1">整体更新</span>
            <span v-else-if="scope.row.modeType === 2">热更新</span>
          </template>
        </el-table-column>
        <el-table-column v-if="modeType === 2" prop="title" label="标题"></el-table-column>
        <el-table-column v-if="modeType === 1" prop="cue" label="升级提示语" show-overflow-tooltip></el-table-column>
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.ISPUBLISH_STATE_CONTENT[scope.row.isPublish]"></wt-enum-content></template>
        </el-table-column>
        <el-table-column prop="createTime" label="发布时间"></el-table-column>
        <el-table-column prop="username" label="创建人"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <!-- <wt-text-button v-has-any-premission="['RTVERSIONFILE:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['RTVERSIONFILE:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.isDefault == 0" v-has-any-premission="['RTVERSIONFILE:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button> -->
            <wt-text-button v-if="scope.row.isPublish === 0" @click="onClickPublish(scope.row)">发布</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 1" @click="onClickPublish(scope.row)">取消发布</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 0" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 1" @click="onClickView(scope.row)">详情</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 0" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-page-dialog ref="detailDialog" title="版本表详情" :modeType="modeType">
       <rtVersionFile-detail :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.detailDialog.close()"></rtVersionFile-detail>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'rtVersionFileList',
  data() {
    return {
      wtFileServerUrl: process.env.VUE_APP_FILE_SERVER_URL,
      modeType: 1, // 列表 1/整体更新 2/热更新
      requestListUrl: '/rtVersionFile/list',
      requestDeleteUrl: '/rtVersionFile/delete',
      requestDetailUrl: '/rtVersionFile/detail',
      updateTitle: '热更新列表',
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {},
        pageData: { currentPage: 1, pageSize: 20, sortName: 'createTime', sortOrder: 'desc' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    onClickPublish(rowData) {
      const that = this
      const isPublish = rowData.isPublish === 0 ? '发布' : '取消发布'
      this.$confirm('是否' + isPublish + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.form.isFromLoading = true
        let url = '/rtVersionFile/is_publish'
        if (this.modeType === 1) {
          url = '/rtVersionFile/is_publish'
        } else if (this.modeType === 2) {
          url = '/rtVersionFileHotfix/is_publish'
        }
        that.$wtRequest({
          url: url,
          method: 'post',
          data: {
            id: rowData.id,
            isPublish: rowData.isPublish === 0 ? 1 : 0
          }
        }).then((resp) => {
          if (resp.isSuccess) {
            that.$message({ type: 'success', message: isPublish + '成功' })
            that.$refs.dataForm.requestDataList()
          } else {
            that.form.isFromLoading = false
          }
        }).catch(() => {
          that.form.isFromLoading = false
        })
      }).catch(() => {})
    },
    onClickView(rowData) {
      this.form.detailViewType = 'view'
      this.form.detailData = rowData
      this.$refs.detailDialog.open()
    },
    onClickEdit(rowData) {
      this.form.detailViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.detailDialog.open()
    },
    onClickAdd() {
      this.form.detailViewType = 'add'
      this.$refs.detailDialog.open()
    },
    onClickDelete(rowData) {
      this.$refs.dataForm.onClickDelete(rowData.id)
    },
    onChangeList() {
      this.form.formResult = {}
      for (const key in this.form.formData) {
        this.form.formData[key] = ''
      }
      if (this.modeType === 1) {
        this.modeType = 2
        this.requestListUrl = '/rtVersionFileHotfix/list'
        this.requestDetailUrl = '/rtVersionFileHotfix/detail'
        this.requestDeleteUrl = '/rtVersionFileHotfix/delete'
        this.updateTitle = '整体更新列表'
        this.$nextTick(() => {
          this.$refs.dataForm.requestDataList()
        })
      } else if (this.modeType === 2) {
        this.modeType = 1
        this.requestListUrl = '/rtVersionFile/list'
        this.requestDetailUrl = '/rtVersionFile/detail'
        this.requestDeleteUrl = '/rtVersionFile/delete'
        this.updateTitle = '热更新列表'
        this.$nextTick(() => {
          this.$refs.dataForm.requestDataList()
        })
      }
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
