<template>
  <div class="wt-container">
    <div>
      <h1>{{testValue}}</h1>
    </div>
    <div>
      <el-button type="primary">测试</el-button>
      <el-button type="primary" size="default">测试</el-button>
      <el-button type="primary" size="medium">测试</el-button>
      <el-button type="primary" size="small">测试</el-button>
      <el-button type="primary" size="mini">测试</el-button>
    </div>
    <div>
      <el-input v-model="testValue"></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Blank',
  data() {
    return {
      testValue: ''
    }
  },
  mounted() {
    this.testValue = this.$route.name
  },
  methods: {
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
