<template>
  <div class="wt-default-layout">
    <div class="wt-silder" :class="{'is-silder-close': !sidebar.opened}">
      <div class="wt-silder-scroller wt-scroll">
        <div class="wt-logo-container wt-default-logo-container">
          <div class="wt-logo-wrapper">{{moduleList[0].meta.title}}</div>
        </div>
        <div class="wt-menu-wrapper">
          <el-menu class="wt-menu" :collapse="!sidebar.opened" :default-active="defaultOpenedMenuName">
            <wt-default-menu-item v-if="routeItem.meta && !routeItem.meta.hidden"
                                  v-for="(routeItem, routeIndex) in moduleList" :key="routeIndex"
                                  :data="routeItem" :level="routeIndex + ''"></wt-default-menu-item>
          </el-menu>
        </div>
        <div class="wt-menu-sidebar">
          <div class="wt-menu-sidebar-content" @click="onClickFold">
            <i class="wt-fold" :class="[sidebar.opened ? 'el-icon-s-fold' : 'el-icon-s-unfold']"></i>
            <span class="wt-menu-sidebar-text">收起导航</span>
          </div>
        </div>
      </div>
    </div>
    <div class="wt-main" :class="{'is-silder-close': !sidebar.opened, 'is-tabs-hidden': !theme.isTabEnable}">
      <div class="wt-layout-header wt-fixed-header wt-default-fixed-header">
        <div class="wt-nav">
          <el-row :gutter="10" class="wt-nav-row">
            <el-col :span="12">
              <div class="wt-nav-left-panel">
                <wt-breadcrumb v-if="theme.isBreadcrumbEnable" />
              </div>
            </el-col>
            <el-col :span="12">
              <div class="wt-nav-right-panel">
                <i class="wt-nav-icon el-icon-full-screen"></i>
                <wt-user-panel />
              </div>
            </el-col>
          </el-row>
        </div>
        <wt-tabs v-if="theme.isTabEnable" />
      </div>
      <div id="wt-app-main" class="wt-app-main" :class="{'is-silder-close': !sidebar.opened, 'is-tabs-hidden': !theme.isTabEnable}">
        <section>
          <router-view></router-view>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WtBreadcrumb from '../../breadcrumb/WtBreadcrumb'
import WtTabs from '../../tabs/WtTabs'
import WtUserPanel from '../../userPanel/WtUserPanel'
import WtDefaultMenuItem from '../../menu/WtDefaultMenuItem'
export default {
  name: 'WtDefaultLayout',
  data() {
    return {
      activeIndex: '',
      appName: process.env.VUE_APP_NAME
    }
  },
  components: {
    WtTabs,
    WtBreadcrumb,
    WtUserPanel,
    WtDefaultMenuItem
  },
  methods: {
    onClickFold() {
      this.$store.dispatch('toggleSideBar')
    },
    onClickMenuSelect(key, keyPath) {
      console.log('onClickMenuSelect', key, keyPath)
    }
  },
  computed: {
    defaultOpenedMenuName() {
      if (this.menuNameList && this.menuNameList.length > 0) {
        return this.menuNameList[this.menuNameList.length - 1]
      }
      return ''
    },
    ...mapState({
      theme: state => state.app.theme,
      menuNameList: state => state.app.menuNameList,
      mainMenuName: state => state.app.mainMenuName,
      subMenuName: state => state.app.subMenuName,
      moduleList: state => state.auth.moduleList
    }),
    ...mapGetters([
      'sidebar'
    ])
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/variables.scss";

  .wt-default-layout {
    .wt-main {
      margin-left: $--wt-default-silder-width;
      padding-top: calc(#{$--wt-default-header-height} + #{$--wt-tabs-height});
      &.is-silder-close {
        margin-left: $--wt-default-silder-min-width;
        .wt-layout-header {
          width: calc(100% - #{$--wt-default-silder-min-width});
        }
      }
      &.is-tabs-hidden{
        padding-top: $--wt-default-header-height;
      }
    }
    .wt-silder {
      background-color: var(--theme-silder-bg-color);
      width: $--wt-default-silder-width;
      .wt-silder-scroller {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .wt-logo-container {
        height: $--wt-default-logo-height;
        display: flex;
        align-items: center;
        justify-content: center;
        .wt-logo-wrapper {
          // background-color: #325AB4;
          // width: 160px;
          font-size: 16px;
          height: 28px;
          line-height: 28px;
          text-decoration: none;
          color: var(--theme-slider-item-text-color);
        }
      }
      .wt-menu-wrapper {
        position: fixed;
        height: calc(100% - #{$--wt-default-logo-height} - #{$--wt-default-silderbar-height});
        width: $--wt-default-silder-width;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
      .wt-menu-sidebar {
        width: $--wt-default-silder-width;
        height: $--wt-default-silderbar-height;
        background-color: var(--theme-silder-bg-color);
        border-top: 1px solid var(--theme-slider-border-color);
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 20px;
        .wt-menu-sidebar-content {
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          color: var(--theme-slider-item-text-color);
          cursor: pointer;
        }
      }

      &.is-silder-close {
        width: $--wt-default-silder-min-width;
        .wt-logo-wrapper{
          width: 44px;
          height: 35px;
        }
        .wt-menu-wrapper {
          width: $--wt-default-silder-min-width;
        }
        .wt-menu-sidebar{
          width: $--wt-default-silder-min-width;
          .wt-menu-sidebar-content {
            justify-content: center;
          }
          .wt-fold {
            margin: 0;
          }
          .wt-menu-sidebar-text {
            display: none;
          }
        }
      }
    }
    .wt-app-main{
      >section{
        >[class*=-container]{
          min-height: calc(100vh - 40px - #{$--wt-default-header-height} - #{$--wt-tabs-height});
        }
      }
      &.is-tabs-hidden{
        > section {
          > [class*=-container] {
            min-height: calc(100vh - 40px - #{$--wt-default-header-height});
          }
        }
      }
    }
  }

  .wt-default-fixed-header {
    transition: all .3s cubic-bezier(.645, .045, .355, 1), border 0s, color .1s, font-size 0s;
    width: calc(100% - #{$--wt-default-silder-width});
    .wt-nav {
      position: relative;
      height: $--wt-default-header-height;
      padding-right: 20px;
      padding-left: 20px;
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: #FFFFFF;
      box-shadow: 0 1px 4px rgb(0 21 41 / 8%);

      .wt-nav-row {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .wt-nav-left-panel {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 100%;
      }
      .wt-nav-right-panel {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: flex-end;
        height: 100%;
        .wt-nav-icon {
          display: none;
          margin-left: 20px;
          font-size: 16px;
          text-align: center;
        }
      }
    }
    .wt-tabs {
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      min-height: 50px;
      padding-right: 20px;
      padding-left: 20px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: #FFFFFF;
      border-top: 1px solid #f6f6f6;
    }
  }

</style>
<style lang="scss">
  @import "@/styles/variables.scss";
  .wt-default-layout {
    .wt-silder {
      .wt-menu {
        width: 100%;
        border-right: 0;
        .el-menu{
          background-color: var(--theme-silder-bg-color);
        }
        .el-menu-item, .el-submenu__title{
          &.is-disabled{
            opacity: 0.75;
            background-color: var(--theme-silder-bg-color)!important;
          }
          height: $--wt-menu-item-height;
          line-height: $--wt-menu-item-height;
          color: var(--theme-slider-item-text-color);
          border-radius: var(--theme-slider-item-radius);
          background-color: var(--theme-silder-bg-color);
        }
        .wt-menu-item, .wt-sub-menu-title {
          display: flex;
          align-items: center;
          height: $--wt-menu-item-height;
          line-height: $--wt-menu-item-height;
          color: var(--theme-slider-item-text-color);
          border-radius: var(--theme-slider-item-radius);
          font-size: 14px;
          padding: 0 20px;
          list-style: none;
          cursor: pointer;
          position: relative;
          box-sizing: border-box;
          white-space: nowrap;
          margin-bottom: 5px;
          i+span{
            margin-left: 16px;
          }
        }
      }
      .wt-menu-item:hover, .wt-sub-menu-title:hover{
        background-color: var(--theme-slider-item-hover-bg-color);
        i, i+span{
          color: var(--theme-slider-item-text-hover-color);
        }
      }
      .wt-menu-item.is-active{
        background-color: var(--theme-slider-item-bg-color);
        i, i+span{
          color: var(--theme-slider-item-text-hover-color);
        }
      }
      .wt-sub-menu-title{
        display: flex;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;
        position: relative;
        .wt-sub-menu-arrow{
          position: absolute;
          top: 50%;
          right: 20px;
          margin-top: -7px;
          font-size: 12px;
          margin-right: 0;
          width: inherit;
        }
      }
      .wt-sub-menu.is-opened {
        > .wt-sub-menu-title > .wt-sub-menu-arrow {
          transform: rotate(180deg);
        }
      }
      &.is-silder-close {
        .wt-menu-item, .wt-sub-menu-title {
          padding: 0!important;
          display: flex;
          justify-content: center;
          span, .wt-sub-menu-arrow{
            display: none;
          }
        }
        .el-submenu__title, .el-tooltip{
          text-align: center;
        }
      }
    }
    .wt-app-main{
      .wt-page-dialog-container{
        margin-left: $--wt-default-silder-width;
        padding-top: calc(#{$--wt-default-header-height} + #{$--wt-tabs-height});
      }
      .wt-page-footer{
        left: $--wt-default-silder-width;
        width: calc(100% - #{$--wt-default-silder-width});
      }
      &.is-silder-close {
        .wt-page-dialog-container{
          margin-left: $--wt-default-silder-min-width;
        }
      }
      &.is-tabs-hidden {
        .wt-page-dialog-container{
          padding-top: $--wt-default-header-height;
        }
      }
    }
  }
</style>
