import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './system/app'
import auth from './system/auth'
import wtTabs from './system/wtTabs'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    auth,
    wtTabs
  },
  getters
})

export default store
