<template>
  <fragment>
    <el-submenu v-if="data.meta && !data.meta.hidden && data.children && data.children.length > 0" :index="data.name">
      <template slot="title">
        <i v-show="theme.isMenuIconVisible" :class="[data.meta && data.meta.icon]"></i>
        <span>{{data.meta && data.meta.title}}</span>
      </template>
      <wt-default-menu-item v-for="(subItem, subIndex) in data.children" :key="'subIndex_'+subIndex" :data="subItem"></wt-default-menu-item>
    </el-submenu>
    <el-menu-item v-else-if="data.meta && !data.meta.hidden" :index="data.name" @click="onClickSubMenu(data)">
      <i v-show="theme.isMenuIconVisible" :class="[data.meta && data.meta.icon]"></i>
      <span slot="title">{{data.meta && data.meta.title}}</span>
    </el-menu-item>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'WtDefaultMenuItem',
  props: {
    data: {
      default: () => {}
    },
    level: {
      default: 0
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    onClickOpen(item) {
      this.$store.dispatch('toggleMenu', item)
    },
    onClickSubMenu(item) {
      this.$router.push({ name: item.name })
    }
  },
  computed: {
    ...mapState({
      theme: state => state.app.theme,
      menuNameList: state => state.app.menuNameList,
      mainMenuName: state => state.app.mainMenuName,
      subMenuName: state => state.app.subMenuName
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
