<template>
  <el-button size="small" @click="onClick" class="wt-button" v-bind="$attrs">
    <slot></slot>
  </el-button>
</template>
<script>
export default {
  name: 'WtButton',
  isAutoComponent: true,
  methods: {
    onClick(e) {
      // 修复偶现第一次点击刷新页面
      e.preventDefault()
      this.$emit('click', '')
    }
  }
}
</script>

<style scoped>
</style>
