<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <el-form :model="detailForm" ref="form" class="wt-page-form" label-width="130px" size="small">
        <!-- <el-form-item class="wt-detail-form-item" label="标题" prop="title"
          >
          <el-input type="textarea" placeholder="请输入" v-model="detailForm.title" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="升级提示语" prop="cue"
          >
          <el-input type="textarea" placeholder="请输入" v-model="detailForm.cue" :disabled="viewType == 'view'" maxlength="500"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="更新方式 0/默认 1/整体更新 2/热更新" prop="modeType"
                      :rules="[ { required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' } ]">
          <el-select multiple placeholder="请选择更新方式 0/默认 1/整体更新 2/热更新" v-model="detailForm.modeType" :disabled="viewType == 'view'">
            <el-option label="请选择" value=""></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="是否强制升级 0/否 1/是" prop="isForce"
                      :rules="[ { required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' } ]">
          <el-select multiple placeholder="请选择是否强制升级 0/否 1/是" v-model="detailForm.isForce" :disabled="viewType == 'view'">
            <el-option label="请选择" value=""></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="系统文件类型  0/默认  1/安卓 2/iOS" prop="systemType"
                      :rules="[ { required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' } ]">
          <el-select multiple placeholder="请选择系统文件类型  0/默认  1/安卓 2/iOS" v-model="detailForm.systemType" :disabled="viewType == 'view'">
            <el-option label="请选择" value=""></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="iOS应用市场地址" prop="iosFileUrl"
          >
          <el-input type="textarea" placeholder="请输入" v-model="detailForm.iosFileUrl" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="Android安卓包路径" prop="androidFileUrl"
          >
          <el-input type="textarea" placeholder="请输入" v-model="detailForm.androidFileUrl" :disabled="viewType == 'view'" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="创建人ID" prop="createUserId"
          >
          <el-input placeholder="请输入" v-model="detailForm.createUserId" :disabled="viewType == 'view'" maxlength="19"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="状态" prop="isPublish"
                      :rules="[{ required: true, message: '请选择启用状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
          <el-switch v-model="detailForm.isPublish" :active-value="1" :inactive-value="0" :disabled="viewType == 'view'"></el-switch>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="创建时间" prop="createTimeRange"
          :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-date-picker type="date" placeholder="请输入" v-model="detailForm.createTime" value-format="yyyy-MM-dd" :disabled="viewType == 'view'"></el-date-picker>
        </el-form-item> -->

        <!-- 更新方式 0/默认 1/整体更新 2/热更新 -->
        <el-form-item class="wt-detail-form-item" label="更新方式" prop="modeType"
          :rules="[ { required: true, message: '请选择更新方式', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' } ]">
          <el-radio-group v-model="detailForm.modeType" :disabled="viewType == 'edit' || viewType == 'view'">
            <el-radio :label="1">整体更新</el-radio>
            <el-radio :label="2">热更新</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 系统选择 1/安卓 2/iOS -->
        <el-form-item class="wt-detail-form-item" label="系统选择" prop="systemType"
          v-if="detailForm.modeType === 1"
          :rules="[ { required: true, message: '请选择系统', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' } ]">
          <el-radio-group v-model="detailForm.systemType" :disabled="viewType == 'view'">
            <el-radio :label="1">安卓</el-radio>
            <el-radio :label="2">iOS</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 是否强制升级 0/否 1/是 -->
        <el-form-item class="wt-detail-form-item" label="是否强制升级" prop="isForce"
          v-if="detailForm.modeType === 1"
          :rules="[ { required: true, message: '请选择是否强制升级', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' } ]">
          <el-radio-group v-model="detailForm.isForce" :disabled="viewType == 'view'">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 系统版本号 -->
        <el-form-item class="wt-detail-form-item" label="系统版本号" prop="version" v-if="detailForm.modeType === 1"
          :rules="[ { required: true, message: '系统版本号不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input placeholder="请输入系统版本号" v-model="detailForm.version" :disabled="viewType == 'view'"></el-input>
        </el-form-item>
        <!-- 系统版本code -->
        <el-form-item class="wt-detail-form-item" label="系统版本code" prop="versionCode" v-if="detailForm.modeType === 1"
          :rules="[ { required: true, message: '系统版本code不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input placeholder="请输入系统版本code" v-model.number="detailForm.versionCode" :disabled="viewType == 'view'"></el-input>
        </el-form-item>
        <!-- 升级提示语 -->
        <el-form-item class="wt-detail-form-item" label="升级提示语" prop="cue" v-if="detailForm.modeType === 1">
          <el-input type="textarea" placeholder="请输入升级提示语" v-model="detailForm.cue" :disabled="viewType == 'view'" maxlength="200"></el-input>
        </el-form-item>
        <!-- Android安装包 -->
        <el-form-item class="wt-detail-form-item" label="Android安装包" prop="androidFileUrl"
          v-if="detailForm.modeType === 1 && detailForm.systemType === 1"
          :rules="[{ required: true, message: '请上传Android安装包', validator: $wtValidator.ValidatorWtUpload, trigger: 'change' }]">
          <wt-file-upload
            v-model="detailForm.androidFileUrl"
            ref="wtOssUploadAndroid"
            data-type="url"
            :max="1"
            accept=".apk"
            :file-limit="1024 * 1024 * 100"
            :readonly="viewType == 'view'">
            <span slot="tip"><span class="important-text">*注：</span>只能上传.apk格式的文件</span>
          </wt-file-upload>
        </el-form-item>
        <!-- iOS应用市场地址 -->
        <el-form-item class="wt-detail-form-item" label="iOS应用市场地址" prop="iosFileUrl"
          v-if="detailForm.modeType === 1 && detailForm.systemType === 2"
          :rules="[ { required: true, message: '请输入应用市场地址', trigger: 'blur' } ]">
          <el-input placeholder="请输入应用市场地址" v-model="detailForm.iosFileUrl" :disabled="viewType == 'view'"></el-input>
        </el-form-item>

        <!-- 热更新版本号 -->
        <!-- <el-form-item class="wt-detail-form-item" label="热更新版本号" prop="version"
          v-if="detailForm.modeType === 2"
          :rules="[ { required: true, message: '请输入热更新版本号', trigger: 'blur' } ]">
          <el-input placeholder="请输入热更新版本号" v-model="detailForm.version" :disabled="viewType == 'view'"></el-input>
        </el-form-item> -->
        <!-- 热更新版本code -->
        <el-form-item class="wt-detail-form-item" label="热更新版本code" prop="versionCode"
          v-if="detailForm.modeType === 2"
          :rules="[ { required: true, message: '请输入热更新版本code', trigger: 'blur' } ]">
          <el-input placeholder="请输入热更新版本code" v-model.number="detailForm.versionCode" :disabled="viewType == 'view'"></el-input>
        </el-form-item>
        <!-- 标题 -->
        <el-form-item class="wt-detail-form-item" label="标题" prop="title"
          v-if="detailForm.modeType === 2"
          :rules="[ { required: true, message: '请输入标题', trigger: 'blur' } ]">
          <el-input placeholder="请输入标题" v-model="detailForm.title" :disabled="viewType == 'view'"></el-input>
        </el-form-item>
        <!-- 热更新文件 -->
        <el-form-item class="wt-detail-form-item" label="热更新文件" prop="fileUrl"
          v-if="detailForm.modeType === 2"
          :rules="[{ required: true, message: '请上传热更新文件', validator: $wtValidator.ValidatorWtUpload, trigger: 'change' }]">
          <wt-file-upload
            v-model="detailForm.fileUrl"
            ref="wtOssUploadHot"
            data-type="url"
            accept=".wgt"
            :max="1"
            :file-limit="1024 * 1024 * 100"
            :readonly="viewType == 'view'">
            <span slot="tip"><span class="important-text">*注：</span>只能上传.wgt格式的文件</span>
          </wt-file-upload>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <wt-button type="primary" v-if="detailForm.isPublish === 0" :loading="isFromLoading" @click="onClickPublish">发布</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <!-- <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['RTVERSIONFILE:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['RTVERSIONFILE:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button> -->
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'rtVersionFileDetail',
  isAutoComponent: true,
  props: ['detailData', 'viewType'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {
        isPublish: 0
      }
    }
  },
  mounted() {
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  methods: {
    onClickPublish() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否发布?').then(() => {
        if (that.viewType === 'add') {
          that.requestAdd(1)
        } else if (that.viewType === 'edit') {
          that.requestSave(1)
        }
      }).catch(() => {})
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestSave()
      }).catch(() => {
      })
    },
    onClickClose() {
      this.$emit('on-close')
    },
    // 查看详情
    requestDetail() {
      const that = this
      if (that.detailData.modeType === 1) {
        this.$wtRequest({
          url: '/rtVersionFile/detail',
          method: 'post',
          data: {
            id: that.detailData.id
          }
        }).then(res => {
          that.detailForm = res.data
          this.$nextTick(() => {
            if (that.detailForm.systemType === 1) {
              that.$refs.wtOssUploadAndroid.updateFileList(that.detailForm.androidFileUrl)
            }
          })
        })
      } else if (that.detailData.modeType === 2) { // 热更新
        this.$wtRequest({
          url: '/rtVersionFileHotfix/detail',
          method: 'post',
          data: {
            id: that.detailData.id
          }
        }).then(res => {
          that.detailForm = res.data
          this.$nextTick(() => {
            that.$refs.wtOssUploadHot.updateFileList(that.detailForm.fileUrl)
          })
        })
      }
    },
    requestSave(isPublish) {
      const that = this
      that.isFromLoading = true
      // const formData = { ...that.detailForm }
      // 整体更新
      if (that.detailForm.modeType === 1) {
        that.$wtRequest({
          url: '/rtVersionFile/update',
          method: 'post',
          // data: formData
          data: {
            isPublish: isPublish || 0, // 是否启用 0/未启用 1/已启用
            id: that.detailData.id,
            modeType: that.detailForm.modeType, // 更新方式 0/默认 1/整体更新 2/热更新
            systemType: that.detailForm.systemType, // 系统文件类型 0/默认 1/安卓 2/iOS
            isForce: that.detailForm.isForce, // 是否强制升级 0/否 1/是
            version: that.detailForm.version, // 系统版本号
            versionCode: that.detailForm.versionCode, // 系统版本code
            cue: that.detailForm.cue || '', // 升级提示语
            androidFileUrl: that.detailForm.systemType === 1 ? that.detailForm.androidFileUrl : '', // Android安卓包路径
            iosFileUrl: that.detailForm.systemType === 2 ? that.detailForm.iosFileUrl : '', // iOS应用市场地址
            title: that.detailForm.title || '' // 标题
          }
        }).then((resp) => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-close')
          that.$emit('on-refresh')
        }).catch(() => {
          that.isFromLoading = false
        })
      } else if (that.detailForm.modeType === 2) { // 热更新
        that.$wtRequest({
          url: '/rtVersionFileHotfix/update',
          method: 'post',
          // data: formData
          data: {
            isPublish: isPublish || 0, // 是否启用 0/未启用 1/已启用
            id: that.detailData.id,
            modeType: that.detailForm.modeType, // 更新方式 0/默认 1/整体更新 2/热更新
            version: that.detailForm.version, // 系统版本号
            versionCode: that.detailForm.versionCode, // 系统版本Code
            title: that.detailForm.title, // 标题
            fileUrl: that.detailForm.fileUrl // 热更新文件路径
          }
        }).then((resp) => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-close')
          that.$emit('on-refresh')
        }).catch(() => {
          that.isFromLoading = false
        })
      }
    },
    requestAdd(isPublish) {
      const that = this
      that.isFromLoading = true
      // const formData = { ...that.detailForm }
      // 整体更新
      if (that.detailForm.modeType === 1) {
        that.$wtRequest({
          url: '/rtVersionFile/add',
          method: 'post',
          // data: formData
          data: {
            isPublish: isPublish || 0, // 是否启用 0/未启用 1/已启用
            modeType: that.detailForm.modeType, // 更新方式 0/默认 1/整体更新 2/热更新
            systemType: that.detailForm.systemType, // 系统文件类型 0/默认 1/安卓 2/iOS
            isForce: that.detailForm.isForce, // 是否强制升级 0/否 1/是
            version: that.detailForm.version, // 系统版本号
            versionCode: that.detailForm.versionCode, // 系统版本code
            cue: that.detailForm.cue || '', // 升级提示语
            androidFileUrl: that.detailForm.systemType === 1 ? that.detailForm.androidFileUrl : '', // Android安卓包路径
            iosFileUrl: that.detailForm.systemType === 2 ? that.detailForm.iosFileUrl : '', // iOS应用市场地址
            title: that.detailForm.title || '' // 标题
          }
        }).then((resp) => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
          that.$emit('on-close')
          that.$emit('on-refresh')
        }).catch(() => {
          that.isFromLoading = false
        })
      } else if (that.detailForm.modeType === 2) { // 热更新
        that.$wtRequest({
          url: '/rtVersionFileHotfix/add',
          method: 'post',
          // data: formData
          data: {
            isPublish: isPublish || 0, // 是否启用 0/未启用 1/已启用
            modeType: that.detailForm.modeType, // 更新方式 0/默认 1/整体更新 2/热更新
            version: that.detailForm.version, // 系统版本号
            versionCode: that.detailForm.versionCode, // 系统版本Code
            title: that.detailForm.title, // 标题
            fileUrl: that.detailForm.fileUrl // 热更新文件路径
          }
        }).then((resp) => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
          that.$emit('on-close')
          that.$emit('on-refresh')
        }).catch(() => {
          that.isFromLoading = false
        })
      }
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
</style>
