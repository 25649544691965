import { render, staticRenderFns } from "./menuGroupDetail.vue?vue&type=template&id=3b7f9fcd&scoped=true&"
import script from "./menuGroupDetail.vue?vue&type=script&lang=js&"
export * from "./menuGroupDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7f9fcd",
  null
  
)

export default component.exports