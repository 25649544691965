export const THEME_ENUM = {
  DEFAULT: {
    name: '默认',
    key: 'default',
    component: () => import('../layouts/default/WtDefaultLayout')
  },
  COLUMN: {
    name: '分栏',
    key: 'column',
    component: () => import('../layouts/normal/WtNormalLayout')
  }
}
