<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  request-list-url="/rtVersionFileHotfix/list" request-delete-url="/rtVersionFileHotfix/delete" request-detail-url="/rtVersionFileHotfix/detail">
      <template slot="search">
        <wt-search-form-item title="状态" v-model="form.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH_ENUM_OPTIONS"/>
        <wt-search-form-item title="创建时间" width="col-3" v-model="form.formData.createTimeRange" type="datetimerange" />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['RTVERSIONFILEHOTFIX:ADD']" @click="onClickAdd">新增</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="id" label="主键"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="fileUrl" label="热更新文件路径"></el-table-column>
        <el-table-column prop="fileSize" label="文件大小"></el-table-column>
        <el-table-column prop="fileHash" label="文件HASH"></el-table-column>
        <el-table-column prop="updateType" label="更新方式 0/默认"></el-table-column>
        <el-table-column prop="createUserId" label="创建人ID"></el-table-column>
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.ISPUBLISH_ENUM_CONTENT[scope.row.isPublish]"></wt-enum-content></template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="updateTime" label="修改时间"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['RTVERSIONFILEHOTFIX:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['RTVERSIONFILEHOTFIX:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.isDefault == 0" v-has-any-premission="['RTVERSIONFILEHOTFIX:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
     <wt-page-dialog ref="detailDialog" title="热更新版本表详情">
       <rtVersionFileHotfix-detail :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.detailDialog.close()"></rtVersionFileHotfix-detail>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'rtVersionFileHotfixList',
  data() {
    return {
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {},
        pageData: { currentPage: 1, pageSize: 20, sortName: 'createTime', sortOrder: 'desc' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    onClickView(rowData) {
      this.form.detailViewType = 'view'
      this.form.detailData = rowData
      this.$refs.detailDialog.open()
    },
    onClickEdit(rowData) {
      this.form.detailViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.detailDialog.open()
    },
    onClickAdd() {
      this.form.detailViewType = 'add'
      this.$refs.detailDialog.open()
    },
    onClickDelete(rowData) {
      this.$refs.dataForm.onClickDelete(rowData.id)
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
